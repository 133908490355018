import React from "react";
import classNames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from "react-number-format";
import isEmail from "validator/lib/isEmail";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  LinearProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import Container from "@material-ui/core/Container";
import SelectFiles from "../SelectFiles";
import config from "./../../config.json";
import I18n from "./../../translation";
import { translations } from "./../../translation";
import styles from "./styles";
import handleScroll from "./../../helpers/srcollToForm";
import isCyrillic from "./../../helpers/isCyrillic";
import { readAsBase64 } from "./../../helpers/readFileList";
import uaLogo from "./../../assets/img/logo_NAZK_black_ua.svg";
import enLogo from "./../../assets/img/logo_NAZK_black_eng.svg";
import ruLogo from "./../../assets/img/logo_NAZK_black_ru.svg";

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles(styles);

const StyledRadio = (props) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.radioRoot}
      disableRipple
      color='default'
      checkedIcon={
        <span className={classNames(classes.icon, classes.checkedIcon)} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const StyledCheckbox = (props) => {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.checkboxroot}
      disableRipple
      color='default'
      checkedIcon={
        <span
          className={classNames(
            classes.checkboxicon,
            classes.checkboxcheckedIcon
          )}
        />
      }
      icon={<span className={classes.checkboxicon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
};

const NumberFormatCustom = ({ ref, onChange, format, ...props }) => {
  const isPhone = props?.id === "authorPhone" || props?.id === "phone";

  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      format={format}
      allowLeadingZeros={true}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={isPhone ? " " : ""}
    />
  );
};

const StringElement = (props) => {
  const [focused, setFocused] = React.useState(false);
  const classes = useStyles();

  return (
    <TextField
      variant='outlined'
      inputProps={{
        maxLength: 256,
      }}
      InputLabelProps={{
        shrink: !!props.value || focused,
      }}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={classes.textField}
      {...props}
    />
  );
};

const Form = ({ location: { pathname } }) => {
  const classes = useStyles();
  const recaptchaRef = React.useRef();

  const actualTranslation = translations[pathname.replace("/", "")] || {};

  const [isAnon, setIsAnon] = React.useState("no");
  const [name, handleChangeName] = React.useState("");
  const [position, handleChangePosition] = React.useState("");
  const [ipn, handleChangeIpn] = React.useState("");
  const [series, handleChangeSeries] = React.useState("");
  const [passportNum, handleChangePassportNum] = React.useState("");
  const [idCard, handleChangeIdCard] = React.useState("");
  const [address, handleChangeAdress] = React.useState("");
  const [phone, handleChangePhone] = React.useState("");
  const [agressionType, handleChangeAgressionType] = React.useState("");

  const [authorName, handleChangeAuthorName] = React.useState("");
  const [email, handleChangeEmail] = React.useState("");
  const [authorPhone, handleChangeAuthorPhone] = React.useState("");
  const [authorAddress, handleChangeAuthorAddress] = React.useState("");
  const [actives, handleChangeActives] = React.useState([]);
  const [otherAuthorinfo, handleChangeOtherAuthorinfo] = React.useState("");

  const [activesArray, handleChangeActivesArray] = React.useState([
    {
      countryOfAssets: "",
      addressOfAssets: "",
      nameBankOrOtherInstitution: "",
      otherData: "",
    },
  ]);

  const updateArrayItem = (name, value, i) => {
    const copiedArray = [...activesArray];
    copiedArray[i][name] = value;
    handleChangeActivesArray(copiedArray);
  };

  const handleDeleteArrayItem = (key) => {
    const copied = [...activesArray];
    delete copied[key];
    handleChangeActivesArray(copied.filter(Boolean));
  };

  const handleAddArrayItem = () =>
    handleChangeActivesArray(
      activesArray.concat([
        {
          countryOfAssets: "",
          addressOfAssets: "",
          nameBankOrOtherInstitution: "",
          otherData: "",
        },
      ])
    );

  const [activesOther, handleActivesOther] = React.useState("");
  const [agressionTypeOther, handleAgressionTypeOther] = React.useState("");
  const [attachesDescription, handleAttachesDescription] = React.useState("");

  const [attaches, onDrop] = React.useState([]);
  const [errors, setError] = React.useState([]);
  const [errorCode, setErrorCode] = React.useState(null);

  const [openAlert, setOpenAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [workflowId, setWorkflowId] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChangeAnon = (event) => setIsAnon(event.target.value);

  const validate = () => {
    let errorArray = [];

    if (!name.length) {
      errorArray.push("name");
    }

    if (series.length && series.length < 2) {
      errorArray.push("series");
    }

    if (passportNum.length && passportNum.length < 6) {
      errorArray.push("passportNum");
    }

    if (idCard.length && idCard.length < 9) {
      errorArray.push("idCard");
    }

    if (idCard.length && idCard.length === 9) {
      errorArray = errorArray.filter((el) => el !== "passportNum");
      errorArray = errorArray.filter((el) => el !== "series");
    }

    if (!agressionType.length) {
      errorArray.push("agressionType");
    }

    // if (
    //   agressionType === actualTranslation.RadioGroup1Text5 &&
    //   !agressionTypeOther.length
    // ) {
    //   errorArray.push("agressionTypeOther");
    // }

    if (!actives.length) {
      errorArray.push("actives");
    }

    // if (
    //   actives.includes(actualTranslation.CheckboxGroup1Text9) &&
    //   !activesOther.length
    // ) {
    //   errorArray.push("activesOther");
    // }

    if (!email.length || !isEmail(email)) {
      errorArray.push("email");
    }

    if (isAnon === "no") {
      if (!authorName.length) {
        errorArray.push("authorName");
      }

      if (!authorPhone.trim().length) {
        errorArray.push("authorPhone");
      }

      if (!authorAddress.length) {
        errorArray.push("authorAddress");
      }
    }

    const checkArray = errorArray.concat(
      activesArray
        .map(({ countryOfAssets }, i) => {
          if (!countryOfAssets.length) {
            return `country${i}`;
          }
          return null;
        })
        .filter(Boolean)
    );

    setError(checkArray);

    return checkArray;
  };

  const clearError = (fieldname) =>
    setError(
      errors.filter((el) => el !== fieldname || el.indexOf(fieldname) === -1)
    );

  const onSubmitWithReCAPTCHA = async (event) => {
    event.preventDefault();

    const hasErrors = validate();

    if (hasErrors.length) {
      const errorId = (hasErrors[0] || "").replace("_str", "").replace("_", "");
      handleScroll(errorId);
      return;
    }

    const token = await recaptchaRef.current.executeAsync();

    const filesToDownload = await Promise.all(
      attaches.map(async (file) => {
        const binary = await readAsBase64(file);
        return {
          name: file.name,
          contentType: file.type,
          fileContent: binary.split(",")[1],
        };
      })
    );

    const body = {
      passportNumber: passportNum,
      passportSerie: series,
      idCardNumber: idCard,
      attachesDescription,
      language: pathname.replace("/", ""),
      userName: name,
      position: position,
      ipn: ipn,
      address: address,
      phone: phone,
      locationOfAssets: activesArray,
      signOfAggression: agressionType,
      otherSignOfAggression: agressionTypeOther,
      assets: actives.map((e) => ({ nameOfAssets: e })),
      otherAssets: activesOther,
      files: filesToDownload,
      anonymous: isAnon === "yes" ? "0" : "1",
      emailApplicant: email,
      nameApplicant: authorName,
      phoneApplicant: authorPhone,
      addressApplicant: authorAddress,
      otherApplicantInfo: otherAuthorinfo,
    };

    setLoading(true);

    const response = await fetch(config?.backendUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        recaptcha: token,
      },
      body: JSON.stringify(body),
    });

    const json = await response.json();

    setLoading(false);

    if (response.status !== 200) {
      setErrorCode(json?.error || json?.message);
      setOpenAlert(true);
    } else {
      setOpen(true);
      setWorkflowId(json?.data?.number);
    }
  };

  const renderFieldError = (fieldName, i) => {
    if (errors.includes(fieldName)) {
      return <I18n t='RequiredField' />;
    }

    if (errors.includes(fieldName + i)) {
      return <I18n t='RequiredField' />;
    }

    if (errors.includes(fieldName + "_str")) {
      return <I18n t='InvalidSgring' />;
    }

    if (errors.includes(`${fieldName}_str_${i}`)) {
      return <I18n t='InvalidSgring' />;
    }

    return null;
  };

  return (
    <div className={classes.root}>
      <p className={classes.reqFieldslabel}>
        <I18n t='RequiredFieldSign' />
      </p>
      <Container className={classes.formContainer}>
        <div id='form' />
        <form onSubmit={onSubmitWithReCAPTCHA} className={classes.form}>
          <div className={classes.mb72}>
            <h3 className={classes.subHeadline}>
              1. <I18n t='FormBlockTitle6' />
            </h3>

            <FormControl component='fieldset'>
              <FormLabel className={classes.checkBoxGroupHelper}>
                <I18n t='isAnon' />
              </FormLabel>
              <div className={classes.firstGroupForm}>
                <RadioGroup
                  row
                  name='isAnon'
                  value={isAnon}
                  onChange={handleChangeAnon}>
                  <FormControlLabel
                    className={classNames(classes.radioItem, classes.mr100)}
                    classes={{ root: classes.radioLabel }}
                    value={"yes"}
                    control={<StyledRadio />}
                    label={<I18n t='Yes' />}
                  />
                  <FormControlLabel
                    className={classes.radioItem}
                    classes={{ root: classes.radioLabel }}
                    value={"no"}
                    control={<StyledRadio />}
                    label={<I18n t='No' />}
                  />
                </RadioGroup>
              </div>
            </FormControl>

            {isAnon === "yes" ? (
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.inputWrapper} sm={6}>
                  <StringElement
                    value={email}
                    label={<I18n t='Email' />}
                    onChange={(event) => {
                      handleChangeEmail(event.target.value);
                      clearError("email");
                    }}
                    id={"email"}
                    error={errors.includes("email")}
                    helperText={
                      errors.includes("email") ? (
                        <I18n t='RequiredEmailField' />
                      ) : null
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  spacing={3}
                  className={classes.inputGroupWrapper}>
                  <Grid item className={classes.inputWrapper} xs={12} sm={6}>
                    <StringElement
                      value={authorName}
                      label={<I18n t='FirstName' />}
                      onChange={(event) => {
                        handleChangeAuthorName(event.target.value);
                        clearError("authorName");
                      }}
                      id={"authorName"}
                      error={
                        errors.includes("authorName") ||
                        errors.includes("authorName_str")
                      }
                      helperText={renderFieldError("authorName")}
                    />
                  </Grid>
                  <Grid item className={classes.inputWrapper} xs={12} sm={6}>
                    <StringElement
                      value={email}
                      label={<I18n t='Email' />}
                      onChange={(event) => {
                        handleChangeEmail(event.target.value);
                        clearError("email");
                      }}
                      id={"email"}
                      error={errors.includes("email")}
                      helperText={
                        errors.includes("email") ? (
                          <I18n t='RequiredEmailField' />
                        ) : null
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  className={classes.inputGroupWrapper}>
                  <Grid item className={classes.inputWrapper} xs={12} sm={6}>
                    <StringElement
                      value={authorPhone}
                      onChange={(event) => {
                        handleChangeAuthorPhone(event.target.value);
                        clearError("authorPhone");
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      inputProps={{
                        maxLength: 256,
                      }}
                      label={<I18n t='PhoneR' />}
                      id={"authorPhone"}
                      error={errors.includes("authorPhone")}
                      helperText={
                        errors.includes("authorPhone") ? (
                          <I18n t='RequiredField' />
                        ) : null
                      }
                    />
                  </Grid>
                  <Grid item className={classes.inputWrapper} xs={12} sm={6}>
                    <StringElement
                      value={authorAddress}
                      label={<I18n t='AddressR' />}
                      onChange={(event) => {
                        handleChangeAuthorAddress(event.target.value);
                        clearError("authorAddress");
                      }}
                      id={"authorAddress"}
                      error={
                        errors.includes("authorAddress") ||
                        errors.includes("authorAddress_str")
                      }
                      helperText={renderFieldError("authorAddress")}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </div>

          <hr className={classes.horizontalLine}></hr>

          <h3 className={classes.subHeadline}>
            2. <I18n t='FormBlockTitle1' />
          </h3>
          <Grid container spacing={3}>
            <Grid
              item
              className={(classes.inputWrapper, classes.zeroVertical)}
              xs={12}
              sm={12}>
              <StringElement
                value={name}
                onChange={(event) => {
                  handleChangeName(event.target.value);
                  clearError("name");
                }}
                label={<I18n t='FirstName' />}
                id={"name"}
                error={errors.includes("name") || errors.includes("name_str")}
                helperText={renderFieldError("name")}
              />
            </Grid>
            <Grid item className={classes.inputWrapper} xs={12} sm={6}>
              <StringElement
                value={position}
                onChange={(event) => {
                  handleChangePosition(event.target.value);
                  clearError("position");
                }}
                label={<I18n t='Position' />}
                id={"position"}
                error={
                  errors.includes("position") || errors.includes("position_str")
                }
                helperText={renderFieldError("position")}
              />
            </Grid>
            <Grid item className={classes.inputWrapper} xs={12} sm={6}>
              <StringElement
                value={ipn}
                onChange={(event) => {
                  handleChangeIpn(event.target.value);
                  clearError("ipn");
                }}
                label={<I18n t='IPN' />}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                inputProps={{
                  maxLength: 14,
                }}
                id={"ipn"}
                error={errors.includes("ipn")}
                helperText={
                  errors.includes("ipn") ? (
                    <I18n t='RequiredField' />
                  ) : (
                    <span className={classes.ipnHelper}>
                      <I18n t='IpnHelper' />
                    </span>
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.textGroupWrapper}>
            <Grid item xs={12} sm={3} className={classes.inputWrapper}>
              <StringElement
                value={series}
                onChange={(event) => {
                  const newValue = event.target.value || "";
                  const cyrillic = newValue
                    .split("")
                    .filter((e) => isCyrillic(newValue))
                    .join("");
                  handleChangeSeries(cyrillic.toUpperCase());
                  clearError("series");
                }}
                label={<I18n t='Serie' />}
                inputProps={{
                  maxLength: 2,
                }}
                id={"series"}
                error={errors.includes("series")}
                helperText={
                  errors.includes("series") ? (
                    <I18n t='SerieValidation' />
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.inputWrapper}>
              <StringElement
                value={passportNum}
                onChange={(event) => {
                  handleChangePassportNum(event.target.value);
                  clearError("passportNum");
                }}
                label={<I18n t='PassportNum' />}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                inputProps={{
                  maxLength: 6,
                }}
                id={"passportNum"}
                error={errors.includes("passportNum")}
                helperText={
                  errors.includes("passportNum") ? (
                    <I18n t='PassportNumberValidation' />
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} className={classes.inputWrapper} sm={1}>
              <p className={classes.orText}>
                <I18n t='or' />
              </p>
            </Grid>

            <Grid item xs={12} sm={4} lg={4} className={classes.inputWrapper}>
              <StringElement
                value={idCard}
                onChange={(event) => {
                  handleChangeIdCard(event.target.value);
                  clearError("idCard");
                }}
                label={<I18n t='idCard' />}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                inputProps={{
                  maxLength: 9,
                }}
                id={"idCard"}
                error={errors.includes("idCard")}
                helperText={
                  errors.includes("idCard") ? (
                    <I18n t='IdCardValidation' />
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} className={classes.inputWrapper} sm={8}>
              <StringElement
                value={address}
                onChange={(event) => {
                  handleChangeAdress(event.target.value);
                  clearError("address");
                }}
                label={<I18n t='Address' />}
                id={"address"}
                error={
                  errors.includes("address") || errors.includes("address_str")
                }
                helperText={renderFieldError("address")}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputWrapper} sm={4}>
              <StringElement
                value={phone}
                onChange={(event) => {
                  handleChangePhone(event.target.value);
                  clearError("phone");
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                inputProps={{
                  maxLength: 256,
                }}
                label={<I18n t='Phone' />}
                id={"phone"}
                error={errors.includes("phone")}
                helperText={
                  errors.includes("phone") ? <I18n t='PhoneValidation' /> : null
                }
              />
            </Grid>
            <Grid item xs={12} className={classes.inputWrapper} sm={12}>
              <StringElement
                value={otherAuthorinfo}
                label={<I18n t='otherAuthorinfo' />}
                onChange={(event) => {
                  handleChangeOtherAuthorinfo(event.target.value);
                }}
                id={"otherAuthorinfo"}
                error={
                  errors.includes("otherAuthorinfo") ||
                  errors.includes("otherAuthorinfo_str")
                }
                helperText={renderFieldError("otherAuthorinfo")}
              />
            </Grid>
          </Grid>

          <hr className={classes.horizontalLine}></hr>

          <h3 className={classes.subHeadline}>
            3. <I18n t='FormBlockTitle2' />
          </h3>
          <FormControl
            component='fieldset'
            className={
              agressionType === actualTranslation.RadioGroup1Text5
                ? null
                : classes.mb72
            }
            error={errors.includes("agressionType")}>
            <RadioGroup
              aria-label='aggression type'
              name='agressionType'
              value={agressionType}
              className={classes.radioWrapper}
              onChange={(event) => {
                handleChangeAgressionType(event.target.value);
                clearError("agressionType");
                handleAgressionTypeOther("");
              }}
              id={"agressionType"}>
              <FormControlLabel
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
                value={actualTranslation.RadioGroup1Text1}
                control={<StyledRadio />}
                label={<I18n t='RadioGroup1Text1' />}
              />
              <FormControlLabel
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
                value={actualTranslation.RadioGroup1Text2}
                control={<StyledRadio />}
                label={<I18n t='RadioGroup1Text2' />}
              />
              <FormControlLabel
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
                value={actualTranslation.RadioGroup1Text3}
                control={<StyledRadio />}
                label={<I18n t='RadioGroup1Text3' />}
              />
              <FormControlLabel
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
                value={actualTranslation.RadioGroup1Text4}
                control={<StyledRadio />}
                label={<I18n t='RadioGroup1Text4' />}
              />
              <FormControlLabel
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
                value={actualTranslation.RadioGroup1Text5}
                control={<StyledRadio />}
                label={<I18n t='RadioGroup1Text5' />}
              />
            </RadioGroup>

            {errors.includes("agressionType") ? (
              <FormHelperText>{<I18n t='RequiredField' />}</FormHelperText>
            ) : null}
          </FormControl>
          {agressionType === actualTranslation.RadioGroup1Text5 ? (
            <Grid
              item
              xs={12}
              sm={12}
              className={classes.inputWrapper}
              style={{ marginTop: 24 }}>
              <StringElement
                value={agressionTypeOther}
                className={classNames(classes.textField, classes.mb72)}
                label={<I18n t='OtherInformation' />}
                onChange={(event) => {
                  handleAgressionTypeOther(event.target.value);
                  clearError("agressionType");
                }}
                id={"agressionTypeOther"}
                error={
                  errors.includes("agressionTypeOther") ||
                  errors.includes("agressionTypeOther_str")
                }
                helperText={renderFieldError("agressionTypeOther")}
              />
            </Grid>
          ) : null}

          <hr className={classes.horizontalLine}></hr>

          <h3 className={classes.subHeadline}>
            4. <I18n t='FormBlockTitle3' />
            {pathname === "/en" ? (
              <>
                <span className={classes.subHeadlineHelper}>
                  <I18n t='FormBlockTitle31' />
                </span>
              </>
            ) : null}
          </h3>
          <FormControl
            error={errors.includes("actives")}
            className={
              actives.includes(actualTranslation.CheckboxGroup1Text9)
                ? null
                : classes.mb72
            }>
            <FormGroup
              value={actives}
              className={classes.checkBoxesWrapper}
              onChange={(event) => {
                const keyId = event.target.value;

                const newValue = actives.includes(keyId)
                  ? actives.filter((item) => item !== keyId)
                  : actives.concat(keyId);

                handleChangeActives(newValue);
                clearError("actives");

                if (keyId === actualTranslation.CheckboxGroup1Text9) {
                  handleActivesOther("");
                }
              }}
              id={"actives"}>
              <FormControlLabel
                value={actualTranslation.CheckboxGroup1Text1}
                control={<StyledCheckbox />}
                label={<I18n t='CheckboxGroup1Text1' />}
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
              />
              <FormControlLabel
                value={actualTranslation.CheckboxGroup1Text2}
                control={<StyledCheckbox />}
                label={<I18n t='CheckboxGroup1Text2' />}
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
              />
              <FormControlLabel
                value={actualTranslation.CheckboxGroup1Text3}
                control={<StyledCheckbox />}
                label={<I18n t='CheckboxGroup1Text3' />}
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
              />
              <FormControlLabel
                value={actualTranslation.CheckboxGroup1Text4}
                control={<StyledCheckbox />}
                label={<I18n t='CheckboxGroup1Text4' />}
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
              />
              <FormControlLabel
                value={actualTranslation.CheckboxGroup1Text5}
                control={<StyledCheckbox />}
                label={<I18n t='CheckboxGroup1Text5' />}
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
              />
              <FormControlLabel
                value={actualTranslation.CheckboxGroup1Text6}
                control={<StyledCheckbox />}
                label={<I18n t='CheckboxGroup1Text6' />}
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
              />
              <FormControlLabel
                value={actualTranslation.CheckboxGroup1Text7}
                control={<StyledCheckbox />}
                label={<I18n t='CheckboxGroup1Text7' />}
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
              />
              <FormControlLabel
                value={actualTranslation.CheckboxGroup1Text8}
                control={<StyledCheckbox />}
                label={<I18n t='CheckboxGroup1Text8' />}
                className={classes.radioItem}
                classes={{ root: classes.radioLabel }}
              />
              <FormControlLabel
                value={actualTranslation.CheckboxGroup1Text9}
                control={<StyledCheckbox />}
                label={<I18n t='CheckboxGroup1Text9' />}
                classes={{ root: classes.radioLabel }}
              />
            </FormGroup>
            {errors.includes("actives") ? (
              <FormHelperText>{<I18n t='RequiredField' />}</FormHelperText>
            ) : null}
          </FormControl>
          {actives.includes(actualTranslation.CheckboxGroup1Text9) ? (
            <Grid item xs={12} className={classes.inputWrapper} sm={8}>
              <StringElement
                value={activesOther}
                className={classNames(classes.textField, classes.mb72)}
                label={<I18n t='CheckboxGroup1Text9' />}
                onChange={(event) => {
                  handleActivesOther(event.target.value);
                  clearError("activesOther");
                }}
                id={"activesOther"}
                error={
                  errors.includes("activesOther") ||
                  errors.includes("activesOther_str")
                }
                helperText={renderFieldError("activesOther")}
              />
            </Grid>
          ) : null}

          <hr className={classes.horizontalLine}></hr>

          <h3 className={classes.subHeadline}>
            5. <I18n t='FormBlockTitle4' />
          </h3>
          {activesArray.map(
            (
              {
                countryOfAssets,
                addressOfAssets,
                nameBankOrOtherInstitution,
                otherData,
              },
              key
            ) => (
              <div>
                <Grid
                  container
                  spacing={3}
                  className={classes.textGroupWrapper}>
                  {activesArray.length > 1 ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.deleteArrayItemGrid}>
                      <div className={classes.deleteArrayItemWrapp}>
                        <IconButton onClick={() => handleDeleteArrayItem(key)}>
                          <ClearOutlinedIcon />
                        </IconButton>
                      </div>
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    className={classes.inputWrapper}
                    sm={6}
                    style={{ paddingTop: 0 }}>
                    <StringElement
                      value={countryOfAssets}
                      label={<I18n t='Country' />}
                      onChange={(event) => {
                        updateArrayItem(
                          "countryOfAssets",
                          event.target.value,
                          key
                        );
                        clearError("country" + key);
                      }}
                      id={"country" + key}
                      error={
                        errors.includes("country" + key) ||
                        errors.includes(`country_str_${key}`)
                      }
                      helperText={renderFieldError("country", key)}
                    />
                  </Grid>

                  <Grid className={classes.inputWrapper} item xs={12} sm={12}>
                    <StringElement
                      value={addressOfAssets}
                      label={<I18n t='Address' />}
                      onChange={(event) => {
                        updateArrayItem(
                          "addressOfAssets",
                          event.target.value,
                          key
                        );
                        clearError("addressOfAssets_" + key);
                      }}
                      id={"addressOfAssets" + key}
                      error={errors.includes(`addressOfAssets_str_${key}`)}
                      helperText={renderFieldError("addressOfAssets", key)}
                    />
                  </Grid>
                  <Grid item className={classes.inputWrapper} xs={12} sm={12}>
                    <StringElement
                      value={nameBankOrOtherInstitution}
                      label={<I18n t='BankOther' />}
                      onChange={(event) => {
                        updateArrayItem(
                          "nameBankOrOtherInstitution",
                          event.target.value,
                          key
                        );
                        clearError("nameBankOrOtherInstitution_" + key);
                      }}
                      id={"nameBankOrOtherInstitution" + key}
                      error={errors.includes(
                        `nameBankOrOtherInstitution_str_${key}`
                      )}
                      helperText={renderFieldError(
                        "nameBankOrOtherInstitution",
                        key
                      )}
                    />
                  </Grid>

                  <Grid item className={classes.inputWrapper} xs={12} sm={12}>
                    <StringElement
                      value={otherData}
                      label={<I18n t='OtherData' />}
                      onChange={(event) => {
                        updateArrayItem("otherData", event.target.value, key);
                        clearError("otherData_" + key);
                      }}
                      id={"otherData" + key}
                      error={errors.includes(`otherData_str_${key}`)}
                      helperText={renderFieldError("otherData", key)}
                    />
                  </Grid>
                </Grid>
              </div>
            )
          )}
          <div
            className={classes.addNewBlock}
            onClick={() => handleAddArrayItem()}>
            <AddIcon />
            <span>
              <I18n t='AddNew' />
            </span>
          </div>

          <hr className={classes.horizontalLine}></hr>

          <h3 className={classes.subHeadline}>
            6. <I18n t='FormBlockTitle5' />
            <span className={classes.subHeadlineHelper}>
              * <I18n t='FormBlockTitle5Helper' />
            </span>
          </h3>
          <Grid
            item
            xs={12}
            className={classNames(classes.p0xs, classes.inputWrapper)}
            sm={12}>
            <FormLabel>
              <FormHelperText className={classes.hideLg}>
                {<I18n t='AttachesDescription' />}
              </FormHelperText>

              <StringElement
                value={attachesDescription}
                variant={"outlined"}
                className={classNames(
                  classes.textField,
                  classes.textareaField,
                  classes.mb32
                )}
                label={
                  <FormLabel className={classes.hideSm}>
                    <I18n t='AttachesDescription' />
                  </FormLabel>
                }
                rows={5}
                multiline={true}
                inputProps={{
                  maxLength: 3000,
                }}
                onChange={(event) => {
                  handleAttachesDescription(event.target.value);
                  clearError("attachesDescription");
                }}
                id={"attachesDescription"}
                error={errors.includes("attachesDescription_str")}
                helperText={renderFieldError("attachesDescription")}
              />
            </FormLabel>
          </Grid>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12} md={8}>
              <div className={classes.mb72}>
                <SelectFiles attaches={attaches} onDrop={onDrop} />
              </div>
            </Grid>
            <Grid item xs={12} md={8} className={classes.alignCenter}>
              <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={config?.recaptcha}
              />

              {loading ? <LinearProgress className={classes.progress} /> : null}

              <Typography>
                <I18n t='ReviewBeforeSubmit'></I18n>
              </Typography>

              <Button
                className={classes.button}
                disabled={loading}
                variant='contained'
                type='submit'>
                <I18n t='Submit'></I18n>
              </Button>
            </Grid>
          </Grid>
        </form>

        <Snackbar
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <Alert onClose={() => setOpenAlert(false)} severity={"error"}>
            {<I18n t='ErrorMessage' args={{ errorCode }} />}
          </Alert>
        </Snackbar>

        <Dialog
          maxWidth='md'
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby={<I18n t='SuccessMessage' />}
          aria-describedby={<I18n t='SuccessMessageDescription' />}
          classes={{
            paperScrollPaper: classes.paperScrollPaper,
            root: classes.dialogRoot,
            scrollPaper: classes.topScrollPaper,
            paperScrollBody: classes.topPaperScrollBody,
          }}>
          <DialogTitle>
            <div className={classes.logoWrapper}>
              {pathname === "/ua" ? <img src={uaLogo} alt='site logo' /> : null}
              {pathname === "/en" ? <img src={enLogo} alt='site logo' /> : null}
              {pathname === "/ru" ? <img src={ruLogo} alt='site logo' /> : null}
            </div>
            <span className={classes.dialogTitleRootHeadline}>
              <I18n t='SuccessMessageHeader' />
            </span>
            <span className={classes.dialogTitleRoot}>
              <I18n t='SuccessMessage' />
            </span>
          </DialogTitle>
          <DialogContent
            classes={{
              root: classes.dialogContentRootInner,
            }}>
            {/* <p className={classes.reportDate}>
              <I18n
                t='SuccessMessageDate'
                args={{ date: dayjs().format("DD.MM.YYYY HH:mm") }}
              />
            </p> */}

            <DialogContentText
              classes={{
                root: classNames(
                  classes.dialogContentRoot,
                  classes.dialogInfoBlock
                ),
              }}>
              <I18n t='SuccessMessageDescription' />
              <p className={classes.workflowId}>{workflowId}</p>
            </DialogContentText>

            <DialogContentText
              classes={{
                root: classNames(classes.dialogContentRoot, classes.emailText),
              }}>
              <I18n t='EmailInfo' args={{ email }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions
            classes={{
              root: classes.dialogActionsRoot,
            }}>
            <Button
              className={classes.dialogActionButton}
              onClick={() => window.location.reload()}
              variant='contained'
              autoFocus>
              <I18n t='SuccessMessageAction' />
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default Form;
