import React from "react";
import Dropzone from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";
import nextId from "react-id-generator";
import I18n from "./../../translation";
import DeleteIcon from "./../../assets/img/ic_trash.svg";
import styles from "./styles";

const useStyles = makeStyles(styles);

const accept = ".pdf,.docx,.doc,.xlsx,.png,.jpg,.jpeg";
const maxSize = 2097152;

const SelectFiles = ({ attaches, onDrop: onDropCallback }) => {
  const [error, setError] = React.useState(false);

  const classes = useStyles();

  const onDrop = async (files) => {
    if (!files.length) return;
    files[0].id = nextId();
    setError(false);
    onDropCallback(attaches.concat(files));
  };

  const onDropRejected = (data) => {
    const error = (data || [])[0]?.errors[0]?.code;
    setError(error);
  };

  const handleDeleteFile = (attach) => {
    onDropCallback(
      Object.values(attaches)
        .filter(Boolean)
        .filter(({ id }) => id !== attach?.id)
    );
  };

  const children = ({ getRootProps, getInputProps }) => (
    <div
      className={classNames({
        [classes.dropZone]: true,
        [classes.errored]: error,
      })}
      {...getRootProps()}>
      <input {...getInputProps()} />

      <p className={classes.fileLimitTitle}>
        <I18n t='DropFiles'></I18n>
        <span className={classes.textAsLink}>
          <I18n t='SelectFiles'></I18n>
        </span>
      </p>

      <Button variant='outlined' className={classes.downloadButton}>
        <I18n t='DownloadAction'></I18n>
      </Button>

      <p className={classes.fileLimit}>
        (.pdf, .docx, .xlsx, .png, .jpg <I18n t='FileLimit'></I18n>)
      </p>
    </div>
  );

  return (
    <>
      <Dropzone
        accept={accept}
        maxSize={maxSize}
        multiple={false}
        activeClassName={classes.dropZoneActive}
        onDrop={onDrop}
        onDropRejected={onDropRejected}>
        {children}
      </Dropzone>

      <div className={classes.fileNamesWrapper}>
        {attaches.map((attach) => (
          <div className={classes.simleListItem} key={attach?.id}>
            <span className={classes.fileNameField}>{attach?.name}</span>
            <Tooltip title={<I18n t='Delete' />}>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleDeleteFile(attach)}>
                <img src={DeleteIcon} alt='DeleteIcon' />
              </IconButton>
            </Tooltip>
          </div>
        ))}
      </div>

      {error ? (
        <FormControl error={true}>
          <FormHelperText>
            {error === "file-too-large" ? (
              <I18n t='FileSizeLimit'></I18n>
            ) : null}
            {error === "file-invalid-type" ? (
              <I18n t='IncorectFileType'></I18n>
            ) : null}
          </FormHelperText>
        </FormControl>
      ) : null}
    </>
  );
};

export default SelectFiles;
