const styles = (theme) => ({
  root: {
    flexGrow: 1,
    background: "#F2F2F2",
    paddingBottom: 70,
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 40,
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 48,
      paddingRight: 48,
    },
  },
  formContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 36,
      paddingRight: 36,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 28,
      paddingRight: 28,
    },
  },
  successLayaout: {
    background: "#FFF",
    borderRadius: 24,
    paddingTop: 40,
    textAlign: "center",
  },
  form: {
    background: "#FFF",
    borderRadius: 5,
    padding: "64px 40px 20px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 30px 30px",
      borderRadius: 5,
    },
  },
  alignCenter: {
    textAlign: "center",
  },
  button: {
    background: "#F8AF00",
    marginTop: 20,
    marginBottom: 50,
    padding: "16px 58px",
    lineHeight: 18,
    borderRadius: 70,
    boxShadow: "none",
    "& span": {
      color: "#000",
      fontWeight: 400,
      fontSize: 20,
      lineHeight: "18px",
      letterSpacing: "0.75px",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "21px",
      },
    },
    "&:hover": {
      background: "#ffffff",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
      padding: "12px 28px",
    },
  },
  headline: {
    fontFamily: "RobotoMedium",
    fontSize: 32,
    lineHeight: "40px",
    letterSpacing: "-0.02em",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "30px",
      marginBottom: 16,
    },
  },
  reqFieldslabel: {
    marginTop: 20,
    paddingLeft: 30,
    color: "#000",
    marginBottom: 10,
    fontSize: 14,
    lineHeight: "16px",
    fontFamily: "RobotoLight",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginBottom: 18,
      marginLeft: 20,
    },
  },
  w80: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  marginCenter: {
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  subHeadline: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "24px",
    marginBottom: 30,
    color: "#c33",
    fontFamily: "RobotoMedium",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      lineHeight: "27px",
      marginBottom: 24,
      marginTop: 10,
    },
  },
  subHeadlineHelper: {
    fontSize: 12,
    lineHeight: "16px",
    padding: "4px 0",
    display: "block",
    marginTop: 5,
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  textField: {
    width: "100%",
    "& label": {
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      top: -5,
      left: 12,
      color: "#B3B3B3",
      fontFamily: "RobotoLight",
    },
    "& label.Mui-focused": {
      color: "#F8AF00",
      width: "unset",
      top: "0",
    },
    "& .MuiOutlinedInput-root": {
      padding: "14px 30px",
      marginBottom: 12,
      borderRadius: 58,
      lineHeight: "18px",
      "& .MuiOutlinedInput-input": {
        padding: 0,
        borderRadius: 0,
      },
      "&:hover fieldset": {
        borderColor: "#F8AF00",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F8AF00",
      },
    },
  },
  mb72: {
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 22,
    },
  },
  radioItem: {
    marginBottom: 14,
  },
  radioLabel: {
    "& .MuiTypography-root": {
      display: "table-cell",
      fontWeight: 300,
      fontSize: 16,
      lineHeight: "24px",
      paddingLeft: 19,
      fontFamily: "RobotoLight",
      color: "#000",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        paddingLeft: 10,
        lineHeight: "1.3",
      },
    },
  },
  radioRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 25,
    height: 25,
    border: "1px solid #C4C4C4",
  },
  checkedIcon: {
    border: "1px solid #C4C4C4",
    position: "relative",
    "&:before": {
      top: "50%",
      left: "50%",
      content: "''",
      display: "block",
      position: "absolute",
      backgroundColor: "transparent",
      marginTop: -10,
      marginLeft: -7,
      borderRadius: "50%",
      width: 30,
      height: 30,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.5462 1.19221C18.9419 0.587106 17.9606 0.587488 17.3555 1.19221L7.49584 11.0522L3.11365 6.67009C2.50855 6.06498 1.52768 6.06498 0.922579 6.67009C0.317474 7.27519 0.317474 8.25606 0.922579 8.86116L6.40007 14.3387C6.70243 14.641 7.09891 14.7926 7.49542 14.7926C7.89193 14.7926 8.28879 14.6414 8.59115 14.3387L19.5462 3.38325C20.1513 2.77856 20.1513 1.79728 19.5462 1.19221Z' fill='%23CC3333'/%3E%3C/svg%3E%0A\")",
      backgroundRepeat: "no-repeat",
    },
  },
  checkboxroot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checkboxicon: {
    borderRadius: 5,
    width: 22,
    height: 22,
    border: "1px solid #C4C4C4",
  },
  checkboxcheckedIcon: {
    "&:before": {
      display: "block",
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.5462 1.19221C18.9419 0.587106 17.9606 0.587488 17.3555 1.19221L7.49584 11.0522L3.11365 6.67009C2.50855 6.06498 1.52768 6.06498 0.922579 6.67009C0.317474 7.27519 0.317474 8.25606 0.922579 8.86116L6.40007 14.3387C6.70243 14.641 7.09891 14.7926 7.49542 14.7926C7.89193 14.7926 8.28879 14.6414 8.59115 14.3387L19.5462 3.38325C20.1513 2.77856 20.1513 1.79728 19.5462 1.19221Z' fill='%23CC3333'/%3E%3C/svg%3E%0A\")",
      backgroundRepeat: "no-repeat",
      content: '""',
    },
  },
  mr100: {
    marginRight: 100,
  },
  firstGroupForm: {
    marginBottom: 30,
  },
  checkBoxGroupHelper: {
    fontSize: 18,
    lineHeight: "20px",
    marginBottom: 40,
    fontWeight: 300,
    fontFamily: "RobotoLight",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      marginBottom: 20,
    },

    "&.Mui-focused": {
      color: "#000",
    },
  },
  radioWrapper: {
    display: "block",
    columnCount: 2,
    columnGap: 50,
    columnFill: "auto",
    height: 130,
    "& .MuiRadio-root": {
      display: "table-cell",
      verticalAlign: "top",
      paddingTop: 0,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 25,
      },
    },
    "& .MuiFormControlLabel-root": {
      display: "table",
    },
    [theme.breakpoints.down("md")]: {
      height: 160,
    },
    [theme.breakpoints.down("sm")]: {
      columnCount: 1,
      height: "auto",
    },
  },
  checkBoxesWrapper: {
    display: "block",
    columnCount: 3,
    columnGap: 50,
    "& .MuiFormControlLabel-root": {
      display: "table",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 7,
        marginBottom: 0,
      },
    },
    [theme.breakpoints.down("sm")]: {
      columnCount: 2,
      columnGap: 30,
    },
    [theme.breakpoints.down("xs")]: {
      columnCount: 1,
    },
  },
  horizontalLine: {
    height: 1,
    backgroundColor: "#C4C4C4",
    border: "none",
    margin: "0 -12px 40px -12px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  orText: {
    height: "100%",
    alignItems: "center",
    display: "flex",
    fontSize: 14,
    lineHeight: "16px",
    padding: "4px 0",
    marginTop: -10,
    color: "#494949",
    [theme.breakpoints.down("sm")]: {
      marginTop: -4,
    },
  },
  ipnHelper: {
    fontSize: 11,
    lineHeight: "16px",
    padding: "4px 0",
    display: "block",
    marginTop: -10,
    color: "#494949",
  },
  progress: {
    height: 3,
    marginTop: 20,
  },
  textareaField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 15,
    },
  },
  mb32: {
    marginBottom: 32,
  },
  hideLg: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  hideSm: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  dialogRoot: {
    backgroundColor: "#F3F3F3",
    "& .MuiDialog-container": {
      marginTop: "87px",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "#eee",
    },
  },
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
  paperScrollPaper: {
    padding: "30px 170px",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: 16,
    },
  },
  dialogLogo: {
    marginBottom: 20,
    margin: "0 auto",
    width: 190,
    display: "block",
  },
  dialogTitleRootHeadline: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "24px",
    textAlign: "center",
    color: "#c33",
    display: "block",
    marginBottom: 20,
    maxWidth: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "24px",
      marginBottom: 0,
    },
  },
  dialogTitleRoot: {
    fontWeight: 400,
    fontSize: 30,
    lineHeight: "32px",
    textAlign: "center",
    letterSpacing: "-0.02em",
    color: "#000000",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "24px",
      marginBottom: 0,
    },
  },
  dialogContentRoot: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "center",
    color: "#000000",
  },
  workflowId: {
    fontWeight: 400,
    fontSize: 38,
    lineHeight: "40px",
    textAlign: "center",
    color: "#c33",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      lineHeight: "28px",
    },
  },
  dialogActionsRoot: {
    display: "flex",
    justifyContent: "center",
  },
  dialogActionButton: {
    background: "#F8AF00",
    padding: "16px 58px",
    lineHeight: "18px",
    borderRadius: 70,
    marginTop: "14px",
    boxShadow: "none",
    "& span": {
      color: "#000",
      fontWeight: 400,
      fontSize: 20,
      lineHeight: "18px",
      letterSpacing: "0.75px",
      textTransform: "none",
    },
    "&:hover": {
      background: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      padding: "5px 8px",
      width: "100%",
      marginTop: 0,
      marginBottom: 14,
      "& span": {
        fontSize: 14,
      },
    },
  },
  dialogMainText: {
    fontSize: 18,
    lineHeight: "27px",
  },
  logoWrapper: {
    display: "block",
    textAlign: "left",
    margin: "0 auto 10px -150px",
    "& img": {
      width: 210,
      height: 56,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginLeft: 0,
    },
    "& span": {
      display: "inline-block",
      maxWidth: 250,
      fontSize: 18,
      lineHeight: "27px",
      marginLeft: 16,
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        lineHeight: "12px",
      },
    },
  },
  emailText: {
    fontSize: 16,
    lineHeight: "16px",
    fontFamily: "RobotoLight",
    marginTop: 15,
  },
  dialogInfoBlock: {
    background: "#EEE",
    padding: "18px 24px 8px",
    textAlign: "center",
    fontSize: 16,
    lineHeight: "16px",
    fontFamily: "RobotoLight",
    borderRadius: 15,
  },
  dialogContentRootInner: {
    maxWidth: 450,
    margin: "0 auto",
  },
  addNewBlock: {
    marginBottom: 50,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    "& svg": {
      fill: "#000",
      cursor: "pointer",
    },
    "& span": {
      fontSize: 16,
      lineHeight: "18px",
      color: "#000",
      paddingLeft: 18,
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "18px",
      },
    },
  },
  deleteArrayItemGrid: {
    padding: "0!important",
    paddingTop: "10px!important",
  },
  deleteArrayItemWrapp: {
    display: "flex",
    justifyContent: "flex-end",
  },
  reportDate: {
    color: "#263238",
    opacity: 0.75,
    fontSize: 16,
    lineHeight: "24px",
    marginBottom: 32,
    textAlign: "center",
  },
  inputWrapper: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "12px!important",
      paddingRight: "12px!important",
      paddingTop: "0px!important",
    },
  },
  emailWrapper: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 0,
    },
  },
  inputGroupWrapper: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 10,
    },
  },
  p0xs: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px!important",
      paddingRight: "0px!important",
      paddingTop: "0px!important",
    },
  },
  zeroVertical: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: "0px!important",
      paddingBottom: "0px!important",
      marginTop: "0px!important",
      marginBottom: "0px!important",
    },
  },
  textGroupWrapper: {
    paddingBottom: 20,
  },
});

export default styles;
