import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./containers/Home";
import Header from "./components/Header";
import "reset-css";
import "./assets/css/App.css";
import "./assets/css/Menu.css";

const base = "/:locale(ua|en|ru)?";

const App = () => (
  <Router>
    <>
      <Route exact path={base} component={Header} />
      <Route exact path={base} component={Home} />
    </>
  </Router>
);

export default App;
