const styles = (theme) => ({
  root: {
    marginTop: 10,
    marginBottom: 10,
    display: "block",
  },
  errored: {
    border: "2px solid red!important",
  },
  dropZone: {
    padding: 26,
    cursor: "pointer",
    outline: "none",
    textAlign: "center",
    border: "#F8AF00 1px solid",
    borderRadius: 15,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      border: "none",
    },
    "&:hover": {
      border: "##C33 1px dashed",
    },
  },
  dropZoneActive: {
    background: "#cdd7e3",
  },
  fileLimit: {
    marginTop: 10,
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: "-0.02em",
    opacity: 0.5,
  },
  fileLimitTitle: {
    margin: "0 auto",
    maxWidth: 295,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  textAsLink: {
    color: "#0054ac",
    borderBottom: "1px solid #0054ac",
  },
  fileNamesWrapper: {
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  fileNameField: {
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  simleListItem: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginTop: 15,
      alignItems: "center",
    },
  },
  iconButton: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      "& img": {
        width: 20,
      },
    },
  },
  downloadButton: {
    borderColor: "#F8AF00",
    padding: "16px 58px",
    lineHeight: "18px",
    borderRadius: 70,
    display: "none",
    width: "100%",
    boxShadow: "none",
    "& span": {
      color: "#000",
      fontWeight: 400,
      fontSize: 20,
      lineHeight: "18px",
      letterSpacing: "0.75px",
      textTransform: "none",
    },
    "&:hover": {
      background: "#F8AF00",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      padding: "12px 8px",
      width: "100%",

      display: "block",
      "& span": {
        fontSize: 14,
        lineHeight: "21px",
      },
    },
  },
});

export default styles;
