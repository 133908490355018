const en = {
  SiteName: "National Agency for the Prevention of Corruption",
  ua: "UA",
  en: "EN",
  ru: "RU",
  Menu_SanctionsLists: "Sanctions lists",
  Menu_Foreignershame: "Foreign directors in Russian companies",
  Menu_Foreignersponsors: "International War Sponsors",
  Menu_Avoid: "How to avoid being sanctioned?",
  Menu_Assets: "Assets of war supporters",
  Menu_About: "About project",
  Menu_API: "API",
  Menu_Documents: "Documents",
  Pageheadline1: "Russian ",
  Pageheadline2: "Assets Whistleblower Tool",
  Pageheadline3:
    "Submit a whistleblower report and help identify assets of individuals involved in the Russian aggression against Ukraine.",
  Pageheadline4: " ",
  GenProc: "PROSECUTOR GENERAL’S OFFICE",
  SerieValidation: "Two cyrillic symbols",
  PassportNumberValidation: "Must be 6 numbers",
  IdCardValidation: "Must be 9 numbers",
  PhoneValidation: "Not the correct format",
  DropFiles: "Drag a file here or ",
  SelectFiles: "download it from your media",
  Send: "Submit a report",
  ReviewBeforeSubmit:
    "Please review all details before submitting your report.",
  Submit: "Send",
  SubmitYet: "Send new",
  InfoBlockTitle:
    "The National Agency on Corruption Prevention together with the Office of the Prosecutor General created this resource to search for the assets of people involved in the Russian aggression against Ukraine.",
  InfoBlockText1: "We work in compliance with international standards",
  InfoBlockText2: "We use secure channels",
  InfoBlockText3:
    "We are searching for Russian assets to confiscate and impose sanctions",
  InfoBlockWarnTitle: "Information on assets",
  InfoBlockWarnDescr:
    "Russian officials, oligarchs, opinion leaders and others involved in the financial and informational support of the war in Ukraine will be used to end the war by putting economic pressure on them by confiscating their property, listing them as individuals and organizations. sanctions in on the sanction list in connection with the aggression of the Russian Federation against Ukraine",
  FormHeadline:
    "Report Assets of Persons Involved in the Russian Aggression against Ukraine",
  FormBlockTitle1: "Details of the person whose assets you are reporting",
  FormBlockTitle2:
    "How this person is connected with the Russian aggression against Ukraine",
  FormBlockTitle3: "Asset data",
  FormBlockTitle4: "Location of assets",
  FormBlockTitle5: "Description of assets and supporting documentation",
  FormBlockTitle5Helper:
    "Please include any information that you have available on the assets. Submit supporting documentation below.",
  FormBlockTitle6: "Whistleblower's data",
  FirstName:
    "Last name, first name, and patronymic/middle name (as applicable) *",
  Position: "Position or title",
  IPN: "Taxpayer identification number",
  AddressR: "Address *",
  PhoneR: "Phone number *",
  Address: "Address",
  Phone: "Phone number",
  RadioGroup1Text1: "Supports the Russian government financially",
  RadioGroup1Text2:
    "Supports the Russian government in the media and information sphere",
  RadioGroup1Text3: "Member of a company which holds Russian assets",
  FormBlockTitle31:
    "What types of assets are you reporting? Please select all that are applicable.",
  RadioGroup1Text4:
    "They are on a sanctions list in connection with the Russian aggression against Ukraine",
  RadioGroup1Text5: "Other",
  CheckboxGroup1Text1: "Cash",
  CheckboxGroup1Text2: "Securities",
  CheckboxGroup1Text3: "Cryptocurrency",
  CheckboxGroup1Text4: "Property and other rights",
  CheckboxGroup1Text5: "Real estate",
  CheckboxGroup1Text6: "Movable assets",
  CheckboxGroup1Text7: "Registered legal entities or companies",
  CheckboxGroup1Text8: "Bank accounts",
  CheckboxGroup1Text9: "Other assets",
  isAnon: "Do you wish to report anonymously?",
  Yes: "Yes",
  AccordionTextYes: "Yes, you can",
  No: "No",
  Email: "Email address *",
  Country: "Country *",
  BankOther: "Name of bank or other institution",
  OtherData: "Other identifying data",
  FileLimit: "up to 2 MB",
  FooterMainText:
    "Data on criminal assets may be used for freezing or confiscating assets, or adding their owners to sanctions lists.",
  copyring: "© 2022 All rights reserved",
  faq: "Questions and answers",
  Serie: "Series",
  PassportNum: "Passport number",
  or: "or",
  idCard: "ID card number",

  InfoBlock2Title: "Your report will contribute to ending the war in Ukraine.",
  InfoBlock2Description:
    "Those who support the war against Ukraine will only challenge Russia’s leadership when they face losing what is most valuable to them: their property and their money.",
  InfoBlock2Description2:
    "Among these people are Russian and Belarusian officials, oligarchs, influential opinion leaders, and more.",
  InfoBlock2Helper:
    "We are calling on you to file a whistleblower report identifying their assets abroad. For example:",
  InfoBlockList1: "an apartment in the UK;",
  InfoBlockList2: "gold bars in Switzerland;",
  InfoBlockList3: "yachts moored in EU countries;",
  InfoBlockList4: "estates in the USA or Canada;",
  InfoBlockList5:
    "any other assets of those supporting the war, in any country around the world.",
  InfoBlock2WarnTitle:
    "By reporting this information, you are contributing to ending the war in Ukraine. It puts economic pressure on those fueling the war, holding them accountable for their crimes. Governments around the world can then confiscate the reported assets or include those involved on sanctions lists.",

  InfoBlock2WarnDescr:
    "The National Agency guarantees the confidentiality and anonymity of such communications in accordance with European standards.",

  FaqTitle: "Questions and answers",
  AccordionTitle1: "Who is the whistleblower?",
  AccordionText1:
    "According to international standards, a whistleblower is a person who reports information on harm to the public interest, in particular, information on: human rights violations; criminal offense; food and household items that are dangerous to human life and health; actions or inaction of subjects of power, legal entities, their officials, individuals who have infrined the constitutional order of the country, life, health or safety of man, the environment, peace and security of mankind or created a real threat to such damage.",
  AccordionTitle2: "Who can make a report on the Portal?",
  AccordionText2:
    "Any person who has information on the assets of Russian officials, oligarchs, opinion leaders and other persons involved in financial and information support of the war in Ukraine, participants / founders of companies with assets of the Russian Federation, persons on the sanctions list in connection with the aggression of the Russian Federation against Ukraine. In this case, the information should not be obtained from publicly available sources.",
  AccordionTitle3: "What kind of assets can I include in the report?",
  AccordionText3:
    "Please only include assets which have not been covered in the press and other publicly-available sources.",

  AccordionText31: "You may report on the following assets:",
  AccordionText32: "property (real estate, transport);",
  AccordionText33:
    "monetary assets, property rights (funds in bank accounts, cash, precious metals, e-funds, etc.);",
  AccordionText34:
    "securities, cryptocurrencies (stocks, investment certificates, promissory notes, option certificates, etc.);",
  AccordionText35:
    "intellectual property rights, corporate rights (patents, copyrights, etc.);",
  AccordionText36:
    "natural resource rights (rights to use subsoil or other natural resources);",
  AccordionText37:
    "other assets of individuals or entities who fuel the war in Ukraine.",

  AccordionTitle4: "Can I file a report anonymously?",
  AccordionText4:
    "However, if you choose not to leave contact information, you may be unable to receive the reward that is offered for whistleblowers by some countries where the assets are located, as it will be impossible to establish your identity.",

  AccordionTitle5: "What is the purpose of asset reporting?",
  AccordionText5:
    "Ending the war that Russia started in Ukraine, saving people's lives, the country's infrastructure and democratic values ​​in Europe and the world in general. One of the real and effective ways to end the war in Ukraine is to put economic pressure on those who decide on and finance military action. Such people are indifferent to human destinies, but they are not indifferent to their property. At the risk of losing their property, such people will stop financing the war",
  AccordionText5D:
    "In order to file an asset report, you need to complete 6 simple steps",

  AccordionText6: "How do I file a report?",
  AccordionText51:
    "provide your personal details (name, phone number, address, email), OR choose to submit your report anonymously.",
  AccordionText52:
    "provide details of the person whose assets you are reporting.",
  AccordionText53:
    "from the options listed, select how the person whose assets you are reporting is related to the Russian aggression against Ukraine.",
  AccordionText54: "select which kind of asset you are reporting.",
  AccordionText55:
    "provide information on the location of the assets or any other available details (country, address, name of the bank or institution, etc.).",
  AccordionText56:
    "provide a description of the assets, and upload any supporting documentation you have available.",
  AccordionText57:
    'review details and click the "Send" button to finalize your report.',

  RequiredField: "Required field",
  IncorectFileType: "Invalid file format",
  FileSizeLimit: "The file is too large",
  RequiredEmailField: "Required field. Example example@email.com",
  IpnHelper:
    "* If you are unable to provide a taxpayer number, enter passport data below",
  SuccessMessage: "The message was successfully registered!",
  SuccessMessageDescription: "Unique message ID",
  SuccessMessageAction: "to the main",
  ErrorMessage: ({ errorCode }) =>
    `An error occurred while sending the message: ${errorCode}`,
  OtherInformation: "Other information",
  AttachesDescription: "Describe the assets",
  FaqFilesTitle: "Additional materials",
  FaqFilesFirst: "Statement of the importance of exposing assets",
  FaqFilesSecond: "Receiving a reward for the message",
  Download: "Download",
  Delete: "Delete",
  Copy: "Copy",
  Copied: "Copied",
  EmailInfo: ({ email }) =>
    `You have received an email at ${email} with ID number`,
  AddNew: "Add more asset locations",
  otherAuthorinfo: "Other identifying data",
  InvalidSgring: "Only Cyrillic or Latin characters",
  RequiredFieldSign: "* fields are required",
  FaqHelpLink: "Privacy tips",
  FaqFilesSecond2: "Privacy tips",

  AccordionText7:
    "I am worried that I may be pursued for submitting a report. How can I protect myself?",
  AccordionText72:
    "To protect yourself from being tracked, we encourage you to take the following precautions when submitting a report to ensure anonymity:",
  AccordionText73:
    "do not use the internet at your home or work if you are filing a report with your personal computer or smartphone. Instead, use Wi-Fi in a public place or mobile internet.",
  AccordionText74: "use a VPN ",
  AccordionText741:
    ". This is especially important when using Wi-Fi in a public place or when reporting from the territory of the Russian Federation",
  AccordionText75:
    "use a secure browser (for example, Tor Browser), instead of a regular browser like Chrome or Safari, to remain anonymous.",
  AccordionText76:
    'create a "disposable" email address with a fictitious name. Alternatively, you can use email services that specialize in anonymous mailboxes, such as ProtonMail, 10 Minute Mail, Anonymous Email.',

  AccordionText8:
    "Will I be considered a whistleblower if I report assets of a sanctioned person using this portal?",
  AccordionText81:
    "By international standards, a whistleblower is a person who discloses information about actions performed by individuals or entities that cause harm against public interest.",
  AccordionText82:
    'The legal definition of "whistleblower" differs between countries and jurisdictions. Therefore, whether or not you will be considered a whistleblower may depend on the country or jurisdiction where the asset you report is located.',
  AccordionText83:
    "This is important to consider, because under certain conditions, several countries, including the United States, may provide certain rewards and protections for whistleblowers.",

  RfVpnRecom:
    "We recommend using a VPN to securely submit a report from the territory of the Russian Federation",
  SuccessMessageHeader:
    "Whistleblower Portal - Report Assets of Persons Involved in the Russian Aggression against Ukraine",
  SuccessMessageDate: ({ date }) => `Your message has been received ${date}`,
  DownloadAction: "Download file",

  HeaderSubtitle:
    "See the list of sanctions imposed over the war against Ukraine, and which individuals and companies will soon be affected.",
};

export default en;
