import React from "react";
import { Link as RRLink } from "react-router-dom";
import classNames from "classnames";
import { Button } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import I18n from "./../../translation";
import withLocale from "./../WithLocale";
import LogoMainUk from "./../../assets/img/logows_uk.svg";
import LogoMainEn from "./../../assets/img/logows_en.svg";
import LogoMainRu from "./../../assets/img/logows_ru.svg";
import styles from "./styles";
import handleScroll from "./../../helpers/srcollToForm";
import mainBgImage from "./../../assets/img/main-bg.jpg";
import { translations } from "./../../translation";

const Link = withLocale(RRLink);

const useStyles = makeStyles(styles);

const Header = ({ history, location: { pathname } }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = (redirect) => {
    history.replace(redirect);
    setAnchorEl(null);
  };

  const classes = useStyles();

  React.useEffect(() => {
    const actualTranslation = translations[pathname.replace("/", "")] || {};
    document.title =
      actualTranslation.Pageheadline1 + actualTranslation.Pageheadline2;
  });

  React.useEffect(() => {
    if (pathname !== "/") return;

    const lang = navigator?.language;

    const isUa = /^ua\b/.test(lang);
    const isEn = /^en\b/.test(lang);
    const isRu = /^ru\b/.test(lang);

    if (isUa) {
      history.replace("/ua");
    } else if (isEn) {
      history.replace("/en");
    } else if (isRu) {
      history.replace("/ru");
    } else {
      history.replace("/ua");
    }
  }, [history, pathname]);

  let extPathname = "";

  if (pathname === "/ru") {
    extPathname = "/ru";
  } else if (pathname === "/en") {
    extPathname = "/en";
  } else {
    extPathname = "";
  }

  const renderLink = (key) => {
    const to = `/${key}`;
    const isActive = pathname === to;

    return (
      <div className={classes.linkWrapper}>
        <Link
          ignoreLocale
          to={to}
          className={classNames({
            [classes.link]: true,
            [classes.linkActive]: isActive,
          })}>
          <I18n t={key} />
        </Link>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Container
          maxWidth={false}
          style={{ backgroundImage: `url(${mainBgImage})` }}
          className={classes.containerBg}>
          <Grid container spacing={3} className={classes.gridHeader}>
            <Grid container spacing={3} className={classes.containerWidth}>
              <Grid
                item
                xs={5}
                sm={2}
                md={1}
                className={classNames(classes.menuWrapper, classes.flex)}>
                <div className='hamburgerMenu'>
                  <Checkbox className={classes.checkBoxMenu}></Checkbox>
                  <label className='menu__btn' htmlFor='menu__toggle'>
                    <span></span>
                  </label>
                  <ul className='menu__box'>
                    <li className='submenu__items'>
                      <a
                        className='menu__item '
                        href={`https://sanctions.nazk.gov.ua${extPathname}/sanction-lists/`}>
                        <I18n t='Menu_SanctionsLists' />
                      </a>
                    </li>
                    <li>
                      <a
                        className='menu__item '
                        href={`https://sanctions.nazk.gov.ua${extPathname}/avoid-sanctions/`}>
                        <I18n t='Menu_Avoid' />
                      </a>
                    </li>
                    <li>
                      <a
                        className='menu__item '
                        href={`https://sanctions.nazk.gov.ua${extPathname}/shame/`}>
                        <I18n t='Menu_Foreignershame' />
                      </a>
                    </li>

                    <li>
                      <a
                        className='menu__item '
                        href={`https://sanctions.nazk.gov.ua${extPathname}/boycott/`}>
                        <I18n t='Menu_Foreignersponsors' />
                      </a>
                    </li>
                    <li>
                      <a
                        className='menu__item '
                        href={`https://sanctions.nazk.gov.ua${extPathname}/person-assets/`}>
                        <I18n t='Menu_Assets' />
                      </a>
                    </li>
                    <li>
                      <a
                        className='menu__item'
                        href={`https://sanctions.nazk.gov.ua${extPathname}/about/`}>
                        <I18n t='Menu_About' />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className={classes.logoWrapper}>
                  <a href={`https://sanctions.nazk.gov.ua${extPathname}/`}>
                    <img
                      className={
                        pathname === "/en" ? classes.logoImgEn : classes.logoImg
                      }
                      src={
                        pathname === "/ru"
                          ? LogoMainRu
                          : pathname === "/en"
                          ? LogoMainEn
                          : LogoMainUk
                      }
                      alt='site logo'
                    />
                  </a>
                </div>
              </Grid>

              <Grid item xs={1} sm={8} md={10} className={classes.flex}>
                <nav>
                  <ul className='topMenu'>
                    <li>
                      <a
                        href={`https://sanctions.nazk.gov.ua${extPathname}/sanction-lists/`}>
                        <I18n t='Menu_SanctionsLists' />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://sanctions.nazk.gov.ua${extPathname}/avoid-sanctions/`}>
                        <I18n t='Menu_Avoid' />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://sanctions.nazk.gov.ua${extPathname}/shame/`}>
                        <I18n t='Menu_Foreignershame' />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://sanctions.nazk.gov.ua${extPathname}/boycott/`}>
                        <I18n t='Menu_Foreignersponsors' />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://sanctions.nazk.gov.ua${extPathname}/person-assets/`}>
                        <I18n t='Menu_Assets' />
                      </a>
                    </li>
                  </ul>
                </nav>
              </Grid>

              <Grid
                item
                xs={3}
                sm={2}
                md={1}
                className={classNames(classes.p0xs, classes.langWrapper)}>
                <div className={classes.langSelect}>
                  <Button
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    onClick={handleClick}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        lineHeight: "14px",
                      }}>
                      {(pathname || "").replace("/", "").toUpperCase()}
                    </span>
                    <ArrowDropDownIcon
                      style={{
                        fill: "#979797",
                      }}
                    />
                  </Button>
                  <Menu
                    id='simple-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <MenuItem onClick={() => handleClose("/ua")}>UA</MenuItem>
                    <MenuItem onClick={() => handleClose("/en")}>EN</MenuItem>
                    <MenuItem onClick={() => handleClose("/ru")}>RU</MenuItem>
                  </Menu>
                </div>

                <div className={classes.linksWrapper}>
                  {renderLink("ua")}
                  {renderLink("en")}
                  {renderLink("ru")}
                  <a
                    className={classNames(
                      classes.link,
                      classes.linkText,
                      classes.linkLikeButton
                    )}
                    href={`https://sanctions.nazk.gov.ua${extPathname}/sanction-documents/`}>
                    <I18n t='Menu_Documents' />
                  </a>
                  <a
                    className={classNames(
                      classes.link,
                      classes.linkText,
                      classes.linkLikeButton
                    )}
                    href={`https://sanctions.nazk.gov.ua${extPathname}/api/`}>
                    <I18n t='Menu_API' />
                  </a>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.containerWidth}>
            <h1 className={classes.h1}>
              <span className={classes.firstLine}>
                <I18n t='Pageheadline1' />
              </span>
              <span>
                <I18n t='Pageheadline2' />
              </span>
            </h1>
            <Grid container alignItems='center'>
              <Grid item xs={12} sm={8}>
                <div className={classes.siteNameHelper}>
                  <span>
                    <I18n t='Pageheadline3' />
                  </span>
                  <span>
                    <I18n t='Pageheadline4' />
                  </span>
                </div>
              </Grid>
              <Grid item sm={12} md={4}>
                <Button
                  onClick={() => {
                    window?.handleOpenAccordion("panel7");
                    handleScroll("panel7d-header");
                  }}
                  className={classes.button}>
                  <I18n t='FaqHelpLink' />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Header;
