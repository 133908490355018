const ru = {
  SiteName: "Национальное агентство по предотвращению коррупции",
  ua: "UA",
  en: "EN",
  ru: "RU",
  Menu_SanctionsLists: "Санкционные списки",
  Menu_Foreignershame: "Иностранцы в российских компаниях",
  Menu_Foreignersponsors: "Международные спонсоры войны",
  Menu_Avoid: "Как не попасть под санкции?",
  Menu_Assets: "Активы причастных лиц",
  Menu_About: "О проекте",
  Menu_API: "API",
  Menu_Documents: "Документы",
  Pageheadline1: "Сообщить об активах лиц,",
  Pageheadline2: "причастных к агрессии РФ против Украины",
  Pageheadline3:
    "Разыскиваем активы лиц, связанных с агрессией РФ против Украины. ",
  Pageheadline4: "Присоединяйтесь!",
  GenProc: "ОФИС ГЕНЕРАЛЬНОГО ПРОКУРОРА",
  SerieValidation: "Два кириллических символа",
  PassportNumberValidation: "Должно быть 6 чисел",
  IdCardValidation: "Должно быть 9 чисел",
  PhoneValidation: "Не верный формат",
  DropFiles: "Перетащите сюда файл или ",
  SelectFiles: "загрузите его со своего носителя",
  Send: "Подать сообщение",
  Submit: "Отправить сообщение",
  SubmitYet: "Отправить новое",
  InfoBlockTitle:
    "Национальное агентство по вопросам предотвращения коррупции совместно с Офисом Генерального прокурора создало этот ресурс для розыска активов лиц, причастных к агрессии РФ против Украины",
  InfoBlockText1: "Работаем в соответствии с международными стандартами",
  InfoBlockText2: "Используем защищенные каналы",
  InfoBlockText3:
    "Разыскиваем российские активы для их конфискации и введения санкций",
  InfoBlockWarnTitle: "Информация об активах",
  InfoBlockWarnDescr:
    "Российские чиновники, олигархи, лидеры мнений и другие лица, связанные с финансовой и информационной поддержкой войны в Украине, будут использованы для прекращения войны путем экономического давления на них путем конфискации их имущества, внесения их в списки лиц и организаций, которые находятся под санкциями в связи с агрессией РФ против Украины",
  FormHeadline:
    "Сообщения об активах лиц, связанных с агрессией РФ против Украины",
  FormBlockTitle1: "Информация о лице, про активы которого вы хотите сообщить",
  FormBlockTitle2: "Как это лицо связано с агрессией РФ против Украины",
  FormBlockTitle3: "Данные об активах",
  FormBlockTitle4: "Местонахождение активов",
  FormBlockTitle5:
    "Описание активов и предоставление подтверждающих документов",
  FormBlockTitle5Helper: "при наличии",
  FormBlockTitle6: "Данные о заявителе",
  FirstName: "Фамилия, имя и отчество *",
  Position: "Должность",
  IPN: "РНУКПП или ИНН",
  AddressR: "Адрес *",
  PhoneR: "Телефон *",
  Address: "Адрес",
  Phone: "Телефон",
  RadioGroup1Text1: "Поддерживает финансово режим путина",
  RadioGroup1Text2: "Поддерживает информационный режим путина",
  RadioGroup1Text3: "Участник компании с российскими активами",
  RadioGroup1Text4: "Находится в санкционном списке в связи с агрессией РФ",
  RadioGroup1Text5: "Другое",
  CheckboxGroup1Text1: "Денежные средства",
  CheckboxGroup1Text2: "Ценные бумаги",
  CheckboxGroup1Text3: "Криптовалюта",
  CheckboxGroup1Text4: "Имущественные и другие права",
  CheckboxGroup1Text5: "Недвижимость",
  CheckboxGroup1Text6: "Движимое имущество",
  CheckboxGroup1Text7: "Компании и участие в них",
  CheckboxGroup1Text8: "Банковские счета",
  CheckboxGroup1Text9: "Прочие активы",
  isAnon: "Хотите оставить сообщение анонимно?",
  Yes: "Да",
  AccordionTextYes: "Да",
  No: "Нет",
  Email: "Электронный адрес *",
  Country: "Страна *",
  BankOther: "Наименование банковского или иного учреждения",
  OtherData: "Другие идентифицирующие данные",
  FileLimit: "до 2 Мб",
  FooterMainText:
    "Данные о преступных активах будут использованы для их ареста, конфискации и добавления владельцев в санкционные списки",
  copyring: "© 2022 Все права защищены",
  faq: "Вопросы и ответы",
  Serie: "Серия",
  PassportNum: "Номер паспорта",
  or: "или",
  idCard: "Номер ID-карты",

  InfoBlock2Title: "Ваше сообщение – вклад для окончания войны в Украине!",
  InfoBlock2Description:
    "Те, кто поддерживают войну в Украине, готовы идти против руководства РФ только когда лишаются самого ценного для себя - своего имущества и денег.",
  InfoBlock2Description2:
    "Это российские и белорусские чиновники, олигархи, лидеры мнений и т.д.",
  InfoBlock2Helper: "Сообщите об их имуществе за границей. К примеру о:",
  InfoBlockList1: "квартире в Великобритании;",
  InfoBlockList2: "золотых слитках в Швейцарии;",
  InfoBlockList3: "яхтах, зашвартованных в странах ЕС;",
  InfoBlockList4: "имениях в США, Канаде и других странах.",
  InfoBlock2WarnTitle:
    "Эта информация поможет прекратить войну путем экономического давления на упомянутых лиц. В частности, благодаря конфискации их имущества и внесению их в санкционные списки.",

  InfoBlock2WarnDescr:
    "Национальное агентство гарантирует обеспечение конфиденциальности и анонимности таких сообщений в соответствии с Европейскими стандартами.",
  FaqTitle: "Вопросы и ответы",

  AccordionTitle1: "Кто такой разоблачитель?",
  AccordionText1:
    "Согласно международным стандартам разоблачитель – лицо, сообщающее об информации о вреде общественным интересам, в частности, это информация о: нарушении прав человека; уголовное правонарушение; пищевые продукты и предметы быта, опасные для жизни и здоровья человека; действия или бездействие субъектов властных полномочий, юридических лиц, их должностных или служебных лиц, физических лиц, нанесенных вред конституционному устройству страны, жизни, здоровью или безопасности человека, состоянию окружающей среды, миру и безопасности человечества или создана реальная угроза задачи такой вреда.",
  AccordionTitle2: "Кто может сообщить на Портале?",
  AccordionText2:
    "Любое лицо, имеющее информацию об активах российских чиновников, олигархов, лидеров мнений и других лиц, связанных с финансовой и информационной поддержкой войны в Украине, участников/учредителей компаний с активами РФ, лиц, находящихся в санкционном списке связи с агрессией РФ против Украины. При этом информация должна быть получена из общедоступных источников.",
  AccordionTitle3: "О каких активах можно подать сообщение?",
  AccordionText3:
    "Сообщение необходимо подавать об активах, информация о которых не появлялась в СМИ и неизвестна широкой общественности.",
  AccordionText31: "Это информация о таких активах:",
  AccordionText32: "имущество (недвижимость, транспорт); ",
  AccordionText33:
    "имущественные права, денежные активы (средства, размещенные на банковских счетах, наличные денежные средства, активы в драгоценных (банковских) металлах, электронные средства и т.п.);",
  AccordionText34:
    "криптовалюты, ценные бумаги (акции, инвестиционные сертификаты, векселя, опционные сертификаты и т.п.);",
  AccordionText35:
    "корпоративные права, объекты права интеллектуальной собственности (патенты, авторские права);",
  AccordionText36: "права на использование недр или других природных ресурсов;",
  AccordionText37:
    "другие активы лиц, финансово и информационно поддерживающих войну в Украине.",

  AccordionTitle4: "Можно ли подать сообщение анонимно?",
  AccordionText4:
    "Однако, если вы не оставите контактные данные, вы не сможете получить вознаграждение в случае, если оно предусмотрено законодательством страны, где находятся активы. Ведь таким образом невозможно установить лицо, которое сообщило об активе",

  AccordionTitle5: "Какова цель сообщения об активах?",
  AccordionText5:
    "Прекращение войны, начатой ​​РФ на Украине, сохранение жизней людей, инфраструктуры страны и демократических ценностей в Европе и мире вообще. Одним из реальных и эффективных способов прекращения войны в Украине является экономическое давление на тех, кто принимает решения о военных действиях и тех, кто их финансирует. Такие лица безразличны к человеческим судьбам, но они не безразличны к своему имуществу. Под угрозой остаться без своего имущества такие люди перестанут финансировать войну",
  AccordionText5D:
    "Чтобы подать сообщение об активах, вам необходимо заполнить 6 простых шагов",

  AccordionText6: "Как подать сообщение об активах?",
  AccordionText51:
    "укажите информацию о себе (ФИО, телефон, почтовый адрес, email) или выберите возможность подать сообщение анонимно.",
  AccordionText52: "укажите данные лица, об активах которого вы сообщаете.",
  AccordionText53:
    "выберите из списка, каким образом лицо, об активах которого вы сообщаете, связано с агрессией России против Украины.",
  AccordionText54: "выберите из списка тип активов, о которых вы сообщаете.",
  AccordionText55:
    "укажите информацию о местонахождении активов и других данных при наличии (страна, адрес, название банка, учреждения, другое).",
  AccordionText56:
    "опишите активы и загрузите копии подтверждающих документов в случае из наличия.",
  AccordionText57: "нажмите кнопку “Отправить сообщение”.",

  RequiredField: "Обязательное поле",
  IncorectFileType: "Неверный формат файла",
  FileSizeLimit: "Файл слишком большой",
  RequiredEmailField: "Обязательное поле. Например example@email.com",
  IpnHelper: "* Если отсутствует РНУКПП, заполните паспортные данные",
  SuccessMessage: "Сообщение успешно зарегистрировано!",
  SuccessMessageDescription: "Уникальный идентификатор сообщения",
  SuccessMessageAction: "на главную",
  ErrorMessage: ({ errorCode }) =>
    `При отправке сообщения произошла ошибка: ${errorCode}`,
  OtherInformation: "Дополнительная информация",
  AttachesDescription: "Введите текст",
  FaqFilesTitle: "Дополнительные материалы",
  FaqFilesFirst: "Обращение о важности разоблачения активов",
  FaqFilesSecond: "Получение вознаграждения за сообщение",
  Download: "Загрузить",
  Delete: "Удалить",
  Copy: "Скопировать",
  Copied: "Скопировано",
  EmailInfo: ({ email }) =>
    `Вам отправлено письмо на электронную почту ${email} с номером идентификатора`,
  AddNew: "Добавить еще местонахождение активов",
  otherAuthorinfo: "Другие идентифицирующие данные",
  InvalidSgring: "Только кириллические или латинские символы",
  RequiredFieldSign: "* поля обязательны к заполнению",
  FaqHelpLink: "Как сообщить безопасно",
  FaqFilesSecond2: "Советы по конфиденциальности обличителя",

  AccordionText7:
    "Я переживаю, что меня могут преследовать из-за сообщения об активах. Как защититься?",
  AccordionText72:
    "Чтобы защитить себя от установления и преследования, советуем соблюдать следующие меры предосторожности при подаче сообщения:",
  AccordionText73:
    "не используйте рабочий или домашний Интернет, если вы сообщаете через свой компьютер или мобильный телефон. Воспользуйтесь сетью Wi-Fi в публичном месте или мобильным Интернетом.",
  AccordionText74: "используйте VPN ",
  AccordionText741:
    ", особенно при использовании wi-fi в публичном месте и отправке сообщения с территории России.",
  AccordionText75:
    "используйте защищенный Tor Browser, позволяющий оставаться анонимным, а не обычный веб-браузер.",
  AccordionText76:
    'создайте "одноразовый" email с выдуманным именем. Вы также можете использовать сервисы, специализирующиеся на анонимных почтовых ящиках, например, ProtonMail, 10 Minute Mail, Anonymous Email.',

  AccordionText8:
    "Будете ли вы считаться разоблачителем, если сообщите об активах подсанкционного лица на этом портале?",
  AccordionText81:
    "С точки зрения международных стандартов, разоблачитель – это человек, сообщающий информацию о вреде общественным интересам, причиненном другим лицом или организацией.",
  AccordionText82:
    "В целом определение слова “разоблачитель” различается в законодательстве разных государств. А значит вопрос того, являетесь ли вы разоблачителем, может зависеть от того, в какой стране находится актив, о котором вы сообщили.",
  AccordionText83:
    "Это важно, поскольку в некоторых странах, таких как США и Великобритания, для разоблачителей предусмотрено материальное вознаграждение.",

  RfVpnRecom:
    "Для безопасной подачи сообщения с территории РФ рекомендуем использовать VPN",
  SuccessMessageHeader:
    "Портал сообщений про активы лиц, причастных к агрессии РФ против Украины",
  SuccessMessageDate: ({ date }) => `Ваше сообщение получено ${date}`,
  DownloadAction: "Загрузить файл",

  HeaderSubtitle:
    "На кого уже наложили санкции в связи с войной в Украине и кому следует приготовиться.",
};

export default ru;
