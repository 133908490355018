const styles = (theme) => ({
  root: {
    paddingTop: 96,
    paddingLeft: 80,
    paddingRight: 80,
    paddingBottom: 64,
    background: "#F2F2F2",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: 40,
    },
    "& .MuiContainer-root + .MuiContainer-root": {
      backgroundColor: "rgba(0,0,0,0.8)",
    },
  },
  headline: {
    fontFamily: "RobotoMedium",
    fontSize: 32,
    lineHeight: "40px",
    letterSpacing: "-0.02em",
    marginBottom: 32,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "30px",
      maxWidth: "100%",
      paddingTop: 40,
      marginBottom: 24,
    },
  },
  maxWidth: {
    maxWidth: "100%",
  },
  faqTitle: {
    fontSize: 30,
    lineHeight: "36px",
    fontFamily: "RobotoMedium",
    paddingBottom: 20,
    color: "#C33",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "30px",
      borderBottom: "none",
      paddingBottom: 10,
      marginTop: 0,
    },
  },
  containerBg: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "rgba(0,0,0,0.8)",
    height: 286,
    [theme.breakpoints.down("sm")]: {
      borderBottom: "none",
      paddingBottom: 40,
      paddingTop: 40,
      height: "auto",
      minHeight: 286,
      backgroundSize: "cover",
      backgroundPosition: "right",
    },
    [theme.breakpoints.up("md")]: {
      backgroundSize: "cover",
    },
  },
  downloadLinkTitle: {
    fontSize: 16,
    lineHeight: "18px",
    color: "#fff",
    textAlign: "center",
    minHeight: 36,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      minHeight: "auto",
    },
  },
  downloadLink: {
    marginTop: 10,
    textAlign: "center",
    "& a": {
      display: "inline-block",
      fontSize: 14,
      fontWeight: 500,
      background: "#F8AF00",
      marginTop: 20,
      padding: "5px 40px",
      lineHeight: "26px",
      borderRadius: "70px",

      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        lineHeight: "20px",
        marginTop: 0,
      },
      "& span": {
        color: "#231F20",
      },
    },
    "& a:hover": {
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      opacity: ".85",
      color: "inherit",
      transition: ".3s",
      backgroundColor: "#F8AF00",
      "& span": {
        color: "#000",
      },
    },
  },
  filesBlockTitle: {
    fontSize: 30,
    lineHeight: "36px",
    color: "#000000",
    marginBottom: 34,
    textAlign: "center",
    marginTop: 42,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "30px",
      marginBottom: 26,
      marginTop: 10,
    },
  },
  pt0: {
    padding: 0,
  },
  pt80: {
    paddingLeft: 52,
    paddingRight: 52,
    width: "auto",
    margin: 0,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 24,
      paddingLeft: 24,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 24,
      paddingLeft: 24,
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: 16,
      paddingLeft: 16,
    },
  },

  link: {
    color: "#c33",
    "&:hover": {
      borderBottom: "1px solid #c33",
    },
  },
});

export default styles;
