const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: 0,
  },
  container: {
    backgroundColor: "#f2f2f2",
    padding: "85px 0 0 0",
    [theme.breakpoints.down("md")]: {
      padding: "81px 0 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      padding: "63px 0 0 0",
    },
  },
  containerBg: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingTop: 1,
    paddingBottom: 13,
    [theme.breakpoints.up("lg")]: {
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("md")]: {
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      borderBottom: "none",
      paddingBottom: 40,
      paddingTop: 40,
      height: "auto",
      minHeight: 286,
      backgroundPosition: "left",
    },
  },
  containerWidth: {
    maxWidth: 1538,
    margin: "0 auto",
    alignItems: "center",
  },
  gridHeader: {
    backgroundColor: "#fff",
    width: "100%",
    margin: "0 -24px",
    display: "flex",
    padding: "8px 0 5px",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 63,
    position: "fixed",
    top: 0,
    zIndex: 1111,
    boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%) !important",
    borderBottom: "1px solid #e2e2e2",
    "& .MuiGrid-item": {
      paddingLeft: 30,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0 5px",
      "& .MuiGrid-item": {
        paddingLeft: 15,
      },
    },

    [theme.breakpoints.down("xs")]: {
      width: "calc(100% + 10px)",
      "& .MuiGrid-item": {
        paddingLeft: 23,
      },
    },
  },
  h1: {
    textTransform: "uppercase",
    fontSize: 40,
    lineHeight: "45px",
    padding: "0 0 0 25px",
    margin: "54px auto 30px 45px",
    color: "#fff",
    fontFamily: "RobotoBold",
    fontWeight: 700,
    maxWidth: 590,
    borderLeft: "solid 10px #F8AF00",

    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "30px",
      padding: "0 20px 0 25px",
      margin: "44px auto 0 7px",
    },
  },
  firstLine: {
    color: "#C33",
    display: "block",
  },
  linksWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 2,
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      right: 16,
      top: 16,
      display: "none",
    },
  },
  langSelect: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  linkWrapper: {
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      marginLeft: 14,
    },
  },
  link: {
    fontSize: 16,
    lineHeight: "23px",
    color: "#000",
    whiteSpace: "nowrap",
    fontFamily: "RobotoBold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "16px",
      marginBottom: 5,
      display: "inline-block",
    },

    "&:hover": {
      color: "#C33",
    },
  },
  linkText: {
    fontSize: 14,
    color: "#000",
    lineHeight: "23px",
    whiteSpace: "nowrap",
    padding: "0 15px",
    backgroundColor: "#ffc845",
    borderRadius: 10,

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "16px",
      marginBottom: 5,
      display: "inline-block",
    },

    "&:hover": {
      color: "#C33",
    },
  },
  linkActive: {
    color: "#000",
    borderBottom: "2px solid #263238",
    "&:hover": {
      color: "#000",
      pointerEvents: "none",
    },
  },
  p0: {
    padding: 0,
  },
  linkLikeButton: {
    marginLeft: "20px",
    fontWeight: "300",
    fontFamily: "Roboto",
  },
  button: {
    background: "#F8AF00",
    padding: "16px 58px",
    lineHeight: "18px",
    borderRadius: 70,
    margin: "-24px 20px 0",
    "& span": {
      color: "#000",
      fontWeight: 400,
      fontSize: 20,
      lineHeight: "18px",
      textTransform: "none",
    },
    "&:hover": {
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      opacity: ".85",
      color: "inherit",
      transition: ".3s",
      backgroundColor: "#F8AF00",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 23px 12px",
      margin: "10px 0 20px 25px",
      "& span": {
        fontSize: 17,
      },
    },
  },
  arrowIcon: {
    marginLeft: 10,
  },

  logoWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  alignTop: {
    alignItems: "start",
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginTop: 0,
    },
  },
  flex: {
    display: "flex",
  },
  siteNameHelper: {
    maxWidth: 620,
    margin: "0 0 20px 80px",
    "& span": {
      fontSize: 20,
      lineHeight: "24px",
      fontFamily: "RobotoLight",
      color: "#fff",
      display: "block",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "21px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0 20px 42px",
    },
  },
  logoImg: {
    height: 45,
    [theme.breakpoints.down("md")]: {
      height: 41,
    },
    [theme.breakpoints.down("sm")]: {
      height: 39,
      marginTop: -1,
    },
  },
  logoImgEn: {
    width: 100,
    padding: "6px 0",
    [theme.breakpoints.down("md")]: {
      height: 41,
    },
    [theme.breakpoints.down("sm")]: {
      height: 39,
      marginTop: -1,
    },
  },
  p0xs: {
    [theme.breakpoints.down("sm")]: {
      padding: "0!important",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  langWrapper: {
    [theme.breakpoints.up("1200")]: {
      position: "relative",
      top: "-20px",
    },
  },
});

export default styles;
