import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ScrollToTop from "react-scroll-to-top";
import I18n from "./../../translation";
import styles from "./styles";
import mailBgImage from "./../../assets/img/email-bg.png";

const useStyles = makeStyles(styles);
const scrollToTop = {
  bottom: 100,
  right: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Footer = ({ location: { pathname } }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <span className={classes.footerText}>
              <I18n t='copyring' />
            </span>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.contactWrapper}>
            <span
              style={{ backgroundImage: `url(${mailBgImage})` }}
              className={(classes.footerText, classes.contactEmail)}>
              <a href='mailto:press@nazk.gov.ua'>press@nazk.gov.ua</a>
            </span>
          </Grid>
        </Grid>

        <ScrollToTop smooth color='#000' style={scrollToTop} />
      </Container>
    </div>
  );
};

export default Footer;
