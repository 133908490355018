import React from "react";
import Form from "./../../components/Form";
import Footer from "./../../components/Footer";
import FaqInfo from "./../../components/FaqInfo";

const Home = (props) => (
  <>
    <Form {...props} />
    <FaqInfo {...props} />
    <Footer {...props} />
  </>
);

export default Home;
