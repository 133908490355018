const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "25px 90px",
    borderTop: "8px solid #C33",
    backgroundColor: "#231F20",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingBottom: 40,
    },
  },
  contactWrapper: {
    textAlign: "right",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  footerText: {
    color: "#AAA",
    fontSize: 18,
    lineHeight: "21px",
    fontFamily: "RobotoLight",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      display: "block",
      marginTop: 24,
    },
  },
  contactEmail: {
    color: "#F8AF00",
    backgroundRepeat: "no-repeat",
    backgroundSize: "22px auto",
    backgroundPosition: "center left",
    display: "inline-block",
    padding: "5px 30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 22,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: -12,
    },
    "& a": {
      color: "#F8AF00",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
});

export default styles;
