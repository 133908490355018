import { createI18n } from 'react-router-i18n';

import ua from './ua';
import en from './en';
import ru from './ru';

const locales = ['ua', 'en', 'ru'];
 
const translations = {
    ua,
    en,
    ru
};

const I18n = createI18n(
    locales,
    translations,
);

export { translations };

export default I18n;
