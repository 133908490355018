import React from "react";
import classNames from "classnames";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import I18n from "./../../translation";
import mainBgImage from "./../../assets/img/main-bg.jpg";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    borderRadius: 5,
    marginBottom: 10,
    marginTop: 10,
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    borderRadius: 5,
    minHeight: 61,
    paddingLeft: 0,
    paddingRight: 0,
    "&$expanded": {
      minHeight: 61,
    },
  },
  content: {
    justifyContent: "space-between",
    padding: "8px 40px 4px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 20px",
    },
    "&$expanded": {
      margin: "12px 0",
    },
    "& p": {
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 500,
      paddingRight: 40,
      color: "#000",
      [theme.breakpoints.down("sm")]: {
        paddingRight: 20,
        fontSize: 18,
        lineHeight: "27px",
      },
    },
  },
  expanded: {
    borderBottom: "2px solid transparent",
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    margin: "0 40px",
    padding: "30px 0",
    borderTop: "solid 1px #F8AF00",
    "& p": {
      maxWidth: "100%",
      fontSize: 14,
      lineHeight: "16px",
      fontFamily: "RobotoLight",
    },
    "& .listFaq li": {
      listType: "disk",
      paddingLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 20px",
    },
  },
}))(MuiAccordionDetails);

const FaqInfo = ({ location: { pathname } }) => {
  const [expanded, setExpanded] = React.useState("");

  const classes = useStyles();

  const handleChange = (panel) => (e, newExpanded) =>
    setExpanded(newExpanded ? panel : false);

  window.handleOpenAccordion = setExpanded;

  let firstDownloadLink = "";
  let secondDownloadLink = "";
  let thirdDownloadLink = "";

  if (pathname === "/ua") {
    firstDownloadLink = "/docs/Report_the_property_ukr.pdf";
    secondDownloadLink = "/docs/Reward_memo_ukr.pdf";
    thirdDownloadLink = "/docs/Privacy_ukr.pdf";
  }

  if (pathname === "/en") {
    firstDownloadLink = "/docs/Report_the_property_eng.pdf";
    secondDownloadLink = "/docs/Reward_memo_eng.pdf";
    thirdDownloadLink = "/docs/Privacy_eng.pdf";
  }

  if (pathname === "/ru") {
    firstDownloadLink = "/docs/Report_the_property_ru.pdf";
    secondDownloadLink = "/docs/Reward_memo_ru.pdf";
    thirdDownloadLink = "/docs/Privacy_ru.pdf";
  }

  return (
    <div id='faq'>
      <div className={classNames(classes.root, classes.pt0)}>
        <Container maxwidth='lg' className={classes.pt0}>
          <Grid container spacing={3} className={classes.pt80}>
            <Grid item xs={12} sm={12}>
              <h2 className={classes.faqTitle}>
                <I18n t='FaqTitle' />
              </h2>

              <Accordion
                square
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}>
                <AccordionSummary
                  aria-controls='panel3d-content'
                  id='panel3d-header'
                  className={classes.borderTopXs}>
                  <Typography>
                    <I18n t='AccordionTitle3' />
                  </Typography>
                  <span>
                    {expanded === "panel3" ? <RemoveIcon /> : <AddIcon />}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography style={{ marginBottom: 15 }}>
                      <I18n t='AccordionText3' />
                    </Typography>
                    <ul style={{ listStyleType: "disc", paddingLeft: "40px" }}>
                      <li>
                        <Typography>
                          <I18n t='AccordionText31' />
                        </Typography>
                      </li>

                      <li>
                        <Typography>
                          <I18n t='AccordionText32' />
                        </Typography>
                      </li>

                      <li>
                        <Typography>
                          <I18n t='AccordionText33' />
                        </Typography>
                      </li>

                      <li>
                        <Typography>
                          <I18n t='AccordionText34' />
                        </Typography>
                      </li>

                      <li>
                        <Typography>
                          <I18n t='AccordionText35' />
                        </Typography>
                      </li>

                      <li>
                        <Typography>
                          <I18n t='AccordionText36' />
                        </Typography>
                      </li>

                      <li>
                        <Typography>
                          <I18n t='AccordionText37' />
                        </Typography>
                      </li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                square
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}>
                <AccordionSummary
                  aria-controls='panel4d-content'
                  id='panel4d-header'>
                  <Typography>
                    <I18n t='AccordionTitle4' />
                  </Typography>
                  <span>
                    {expanded === "panel4" ? <RemoveIcon /> : <AddIcon />}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography>
                      <I18n t='AccordionTextYes' />.
                    </Typography>
                    <Typography>
                      <I18n t='AccordionText4' />
                    </Typography>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                square
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}>
                <AccordionSummary
                  aria-controls='panel5d-content'
                  id='panel6d-header'>
                  <Typography>
                    <I18n t='AccordionText6' />
                  </Typography>
                  <span>
                    {expanded === "panel6" ? <RemoveIcon /> : <AddIcon />}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <ul>
                      <li>
                        <Typography>
                          1. <I18n t='AccordionText51' />
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          2. <I18n t='AccordionText52' />
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          3. <I18n t='AccordionText53' />
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          4. <I18n t='AccordionText54' />
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          5. <I18n t='AccordionText55' />
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          6. <I18n t='AccordionText56' />
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          7. <I18n t='AccordionText57' />
                        </Typography>
                      </li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                square
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}>
                <AccordionSummary
                  aria-controls='panel7d-content'
                  id='panel7d-header'>
                  <Typography>
                    <I18n t='AccordionText7' />
                  </Typography>
                  <span>
                    {expanded === "panel7" ? <RemoveIcon /> : <AddIcon />}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography style={{ marginBottom: 15 }}>
                      <I18n t='AccordionText72' />
                    </Typography>
                    <ul>
                      <li>
                        <Typography>
                          1. <I18n t='AccordionText73' />
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          2. <I18n t='AccordionText74' />(
                          <a
                            className={classes.link}
                            href='https://www.security.org/vpn/'>
                            https://www.security.org/vpn/
                          </a>
                          )
                          <I18n t='AccordionText741' />
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          3. <I18n t='AccordionText75' />
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          4. <I18n t='AccordionText76' />
                        </Typography>
                      </li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                square
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}>
                <AccordionSummary
                  aria-controls='panel8d-content'
                  id='panel8d-header'>
                  <Typography>
                    <I18n t='AccordionText8' />
                  </Typography>
                  <span>
                    {expanded === "panel8" ? <RemoveIcon /> : <AddIcon />}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography style={{ marginBottom: 15 }}>
                      <I18n t='AccordionText81' />
                    </Typography>
                    <Typography style={{ marginBottom: 15 }}>
                      <I18n t='AccordionText82' />
                    </Typography>
                    <Typography style={{ marginBottom: 15 }}>
                      <I18n t='AccordionText83' />
                    </Typography>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <h2 className={classes.filesBlockTitle}>
            <I18n t='FaqFilesTitle' />
          </h2>
        </Container>
        <Container className={(classes.pt0, classes.maxWidth)}>
          <Grid
            container
            spacing={5}
            alignContent='center'
            justifyContent='center'
            style={{ backgroundImage: `url(${mainBgImage})` }}
            className={classes.containerBg}>
            <Grid item sm={12} md={3}>
              <p className={classes.downloadLinkTitle}>
                <I18n t='FaqFilesFirst' />
              </p>

              <p className={classes.downloadLink}>
                <a
                  href={firstDownloadLink}
                  download
                  className={classes.downloadButton}>
                  <span>
                    <I18n t='Download' />
                  </span>
                </a>
              </p>
            </Grid>
            <Grid item sm={12} md={3}>
              <p className={classes.downloadLinkTitle}>
                <I18n t='FaqFilesSecond' />
              </p>

              <p className={classes.downloadLink}>
                <a
                  href={secondDownloadLink}
                  download
                  className={classes.downloadButton}>
                  <span>
                    <I18n t='Download' />
                  </span>
                </a>
              </p>
            </Grid>

            <Grid item sm={12} md={3}>
              <p className={classes.downloadLinkTitle}>
                <I18n t='FaqFilesSecond2' />
              </p>

              <p className={classes.downloadLink}>
                <a
                  href={thirdDownloadLink}
                  download
                  className={classes.downloadButton}>
                  <span>
                    <I18n t='Download' />
                  </span>
                </a>
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default FaqInfo;
