const handleScroll = (id) => {
    const form = document.getElementById(id);

    form.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
    });
};

export default handleScroll;
