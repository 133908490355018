const ua = {
  SiteName: "Національне агентство з питань запобігання корупції",
  ua: "UA",
  en: "EN",
  ru: "RU",
  Menu_SanctionsLists: "Санкційні списки",
  Menu_Avoid: "Як не потрапити під санкції?",
  Menu_Assets: "Активи причетних осіб",
  Menu_Foreignershame: "Іноземці в російських компаніях",
  Menu_Foreignersponsors: "Міжнародні спонсори війни",
  Menu_About: "Про проєкт",
  Menu_API: "API",
  Menu_Documents: "Документи",
  Pageheadline1: "Повідомити про активи ",
  Pageheadline2: "осіб, причетних до агресії РФ проти України",
  Pageheadline3:
    "Розшукуємо активи осіб, пов’язаних з агресією РФ проти України. ",
  Pageheadline4: "Долучайтесь!",
  GenProc: "ОФІС ГЕНЕРАЛЬНОГО ПРОКУРОРА",
  SerieValidation: "Два кириличні символи",
  PassportNumberValidation: "Має бути 6 чисел",
  IdCardValidation: "Має бути 9 чисел",
  PhoneValidation: "Невірний формат",
  DropFiles: "Перетягніть сюди файл або ",
  SelectFiles: "завантажте його зі свого носія",
  Send: "Подати повідомлення",
  Submit: "Надіслати повідомлення",
  SubmitYet: "Надіслати нове",
  InfoBlockTitle:
    "Національне агентство з питань запобігання корупції спільно з Офісом Генерального прокурора створили цей ресурс для розшуку активів осіб, причетних до агресії РФ проти України",
  InfoBlockText1: "Працюємо відповідно до міжнародних стандартів",
  InfoBlockText2: "Використовуємо захищені канали",
  InfoBlockText3:
    "Розшукуємо російські активи для їх конфіскації та введення санкцій",
  InfoBlockWarnTitle: "Інформація про активи",
  InfoBlockWarnDescr:
    "Російських чиновників, олігархів, лідерів думок та інших осіб, які пов’язані з фінансовою та інформаційною підтримкою війни в Україні, буде використана для припинення війни шляхом економічного тиску на них шляхом конфіскації їх майна, внесення їх до списків осіб та організацій, які перебувають під санкціями у зв’язку з агресією РФ проти України",
  FormHeadline:
    "Повідомлення про активи осіб, які пов’язані з агресією РФ проти України",
  FormBlockTitle1: "Інформація про особу, про активи якої ви повідомляєте",
  FormBlockTitle2: "Як ця особа пов'язана з агресією РФ проти України",
  FormBlockTitle3: "Дані про активи",
  FormBlockTitle4: "Місцезнаходження активів",
  FormBlockTitle5: "Опис активів та подання підтверджуючих документів",
  FormBlockTitle5Helper: "за наявності",
  FormBlockTitle6: "Дані про заявника",
  FirstName: "Прізвище, ім’я та по батькові *",
  Position: "Посада",
  IPN: "РНОКПП або ІНПП",
  AddressR: "Адреса *",
  PhoneR: "Телефон *",
  Address: "Адреса",
  Phone: "Телефон",
  RadioGroup1Text1: "Підтримує фінансово режим путіна",
  RadioGroup1Text2: "Підтримує інформаційно режим путіна",
  RadioGroup1Text3: "Учасник компанії з російськими активами",
  RadioGroup1Text4: "Знаходиться в санкційному списку у зв’язку з агресією РФ",
  RadioGroup1Text5: "Інше",
  CheckboxGroup1Text1: "Грошові кошти",
  CheckboxGroup1Text2: "Цінні папери",
  CheckboxGroup1Text3: "Криптовалюта",
  CheckboxGroup1Text4: "Майнові та інші права",
  CheckboxGroup1Text5: "Нерухомість",
  CheckboxGroup1Text6: "Рухоме майно",
  CheckboxGroup1Text7: "Компанії та участь в них",
  CheckboxGroup1Text8: "Банківські рахунки",
  CheckboxGroup1Text9: "Інші активи",
  isAnon: "Бажаєте подати повідомлення анонімно?",
  Yes: "Так",
  AccordionTextYes: "Так",
  No: "Ні",
  Email: "Електронна адреса *",
  Country: "Країна *",
  BankOther: "Найменування банківської чи іншої установи",
  OtherData: "Інші ідентифікуючі дані",
  FileLimit: "до 2 Мб",
  FooterMainText:
    "Дані про злочинні активи будуть використані для їх арешту, конфіскації та додання власників до санкційних списків",
  copyring: "© 2022 Війна і санкції",
  faq: "Питання-відповіді",
  Serie: "Серія",
  PassportNum: "Номер паспорту",
  or: "або",
  idCard: "Номер ID-карти",

  InfoBlock2Title: "Ваше повідомлення - внесок для закінчення війни в Україні!",
  InfoBlock2Description:
    "Ті, хто підтримують війну в Україні, готові йти проти керівництва РФ лише коли позбавляються найціннішого для себе - свого майна та грошей.",
  InfoBlock2Description2:
    "Це російські та білоруські чиновники, олігархи, лідери думок тощо. ",
  InfoBlock2Helper: "Повідомте про їхнє майно за кордоном. Наприклад:",
  InfoBlockList1: "квартиру у Великобританії;",
  InfoBlockList2: "золоті злитки у Швейцарії;",
  InfoBlockList3: "яхти, зашвартовані у країнах ЄС;",
  InfoBlockList4: "маєтки у США, Канаді та будь-яких інших країнах.",
  InfoBlock2WarnTitle:
    "Ця інформація допоможе припинити війну шляхом економічного тиску на згаданих осіб. Зокрема завдяки конфіскації їх майна та внесення їх до санкційних списків.",

  InfoBlock2WarnDescr:
    "Національне агентство гарантує забезпечення конфіденційності та анонімності таких повідомлень у відповідності до Європейських стандартів.",

  FaqTitle: "Як повідомити безпечно?",
  AccordionTitle1: "Хто такий викривач?",
  AccordionText1:
    "Відповідно до міжнародних стандартів викривач ˗ особа , яка повідомляє інформацію про шкоду суспільним інтересам, зокрема, це інформація про: порушення прав людини; кримінальне правопорушення; харчові продукти  і предмети побуту, які є небезпечними для життя і здоров’я людини; дії або бездіяльність суб’єктів владних повноважень, юридичних осіб, їх посадових чи службових осіб, фізичних осіб, якими завдано шкоду конституційному устрою країни, життю, здоров’ю або безпеці людини, стану довкілля, миру і безпеці людства або створено реальну загрозу завдання такої шкоди.",
  AccordionTitle2: "Хто може здійснити повідомлення на Порталі?",
  AccordionText2:
    "Будь-яка особа, яка має інформацію про активи російських чиновників, олігархів, лідерів думок та інших осіб, пов'язаних з фінансовою та інформаційною підтримкою війни в Україні, учасників/засновників компаній з активами РФ, осіб, які знаходяться в санкційному списку у зв'язку з агресією РФ проти України. При цьому інформація має бути отримана не з загальнодоступних джерел.",
  AccordionTitle3: "Про які активи можна подати повідомлення?",
  AccordionText3:
    "Повідомлення необхідно подавати про активи, інформація про які не з’являлася у ЗМІ та невідома широкому загалу.",
  AccordionText31: "Це інформація про такі активи: ",
  AccordionText32: "майно (нерухомість, транспорт);",
  AccordionText33:
    "майнові права, грошові активи (кошти, розміщені на банківських рахунках, готівкові кошти, активи у дорогоцінних (банківських) металах, електронні кошти тощо); ",
  AccordionText34:
    "криптовалюти, цінні папери (акції, інвестиційні сертифікати, векселі, опціонні сертифікати тощо);",
  AccordionText35:
    "корпоративні права, об’єкти права інтелектуальної власності (патенти, авторські права тощо);",
  AccordionText36: "права на використання надр чи інших природних ресурсів; ",
  AccordionText37:
    "інші активи осіб, які фінансово та інформаційно підтримують війну в Україні.",

  AccordionTitle4: "Чи можна подати повідомлення анонімно? ",
  AccordionText4:
    "Проте, якщо ви не залишите контактних даних, ви не зможете отримати винагороду у випадку, якщо вона передбачена законодавством країни, де знаходяться активи. Адже буде неможливо встановити особу, яка повідомила про актив.",

  AccordionTitle5: "Яка мета повідомлення про активи?",
  AccordionText5:
    "Припинення війни, яку розпочала РФ в Україні, збереження людських життів, інфраструктури країни та демократичних цінностей в Європі та у світі взагалі. Одним з реальних та ефективних способів припинення війни в Україні є економічний тиск на тих, хто приймає рішення про військові дії та тих, хто їх фінансує. Такі особи байдужі до людських доль, але вони не байдужі до свого майна. Під загрозою залишитись без свого майна, такі люди припинять фінансувати війну",
  AccordionText5D:
    "Для того, щоб подати повідомлення про активи, вам необхідно заповнити 6 простих кроків",

  AccordionText6: "Як подати повідомлення про активи?",
  AccordionText51:
    "зазначте інформацію про себе (ПІБ, телефон, поштова адреса, email) або оберіть можливість подати повідомлення анонімно.",
  AccordionText52: "зазначте дані особи, про активи якої ви повідомляєте.",
  AccordionText53:
    "оберіть зі списку, яким чином особа, про активи якої ви повідомляєте, пов’язана з агресією рф проти України.",
  AccordionText54: "оберіть зі списку, про які активи ви повідомляєте.",
  AccordionText55:
    "зазначте інформацію щодо місцезнаходження активів та інші дані у разі наявності (країна, адреса, назва банку, установи, інше).",
  AccordionText56:
    "опишіть активи та у разі наявності завантажте копії підтверджуючих документів",
  AccordionText57: "натисніть кнопку “Надіслати повідомлення”.",

  RequiredField: "Обов'язкове поле",
  IncorectFileType: "Невірний формат файлу",
  FileSizeLimit: "Файл завеликий",
  RequiredEmailField: "Обов'язкове поле. Наприклад example@email.com",
  IpnHelper: "* Якщо відсутній РНОКПП, заповніть паспортні дані",
  SuccessMessage: "Успішно прийнято!",
  SuccessMessageDescription: "Унікальний ідентифікатор повідомлення",
  SuccessMessageAction: "на головну",
  ErrorMessage: ({ errorCode }) =>
    `Під час відправки повідомлення сталась помилка: ${errorCode}`,
  OtherInformation: "Інша інформація",
  AttachesDescription: "Введіть текст",
  FaqFilesTitle: "Пам’ятки",
  FaqFilesFirst: "Звернення про важливість викривання активів",
  FaqFilesSecond: "Отримання винагороди за повідомлення",
  Download: "Завантажити",
  Delete: "Видалити",
  Copy: "Скопіювати",
  Copied: "Скопійовано",
  EmailInfo: ({ email }) =>
    `Вам надіслано лист на електронну пошту ${email} з номером ідентифікатора`,
  AddNew: "Додати ще місцезнаходження активів",
  otherAuthorinfo: "Інші ідентифікуючі дані",
  InvalidSgring: "Тільки кириличні або латинські символи",
  RequiredFieldSign: "* Поля з зірочками обов'язкові для заповнення",
  FaqHelpLink: "Як повідомити безпечно",
  FaqFilesSecond2: "Поради з конфіденційності викривача",

  AccordionText7:
    "Я переживаю, що мене можуть переслідувати через подання повідомлення. Як захиститися?",
  AccordionText72:
    "Щоб захистити себе від встановлення та переслідування, радимо дотримуватися таких заходів під час подання повідомлення:",
  AccordionText73:
    "не використовуйте робочий чи домашній Інтернет, якщо ви повідомляєте через власний комп’ютер чи мобільний телефон. Скористайтеся мережею Wi-Fi у публічному місці або мобільним Інтернетом.",
  AccordionText74: "використовуйте VPN ",
  AccordionText741:
    ", особливо під час використання Wi-Fi у публічному місці та подання повідомлення з території РФ.",
  AccordionText75:
    "використовуйте захищений Tor Browser, який дозволяє вам залишатися анонімним, а не звичайний веб-браузер.",
  AccordionText76:
    "створіть “одноразовий” email з вигаданим ім’ям. Також ви можете використовувати сервіси, які спеціалізуються на анонімних поштових скриньках, для прикладу, ProtonMail, 10 Minute Mail, Anonymous Email.",
  RfVpnRecom:
    "Для безпечного подання повідомлення з території РФ рекомендуємо використовувати VPN",

  AccordionText8:
    "Чи будете ви вважатися викривачем, якщо повідомите про активи підсанкційної особи на цьому порталі?",
  AccordionText81:
    "З точки зору міжнародних стандартів, викривач - це людина, яка повідомляє інформацію про шкоду суспільним інтересам, заподіяну іншою особою або організацією.",
  AccordionText82:
    "Загалом визначення слова “викривач” різниться у законодавстві різних країн. А отже питання того чи є ви викривачем може залежати від того, у якій країні знаходиться актив, про який ви повідомили.",
  AccordionText83:
    "Це важливо, оскільки за певних умов у багатьох країнах, таких як США та Великобританія, для викривачів передбачена матеріальна винагорода.",

  SuccessMessageHeader:
    "Повідомлення про активи осіб, причетних до агресії РФ проти України",
  SuccessMessageDate: ({ date }) => `Ваше повідомлення отримано ${date}`,
  DownloadAction: "Завантажити файл",

  HeaderSubtitle:
    "На кого вже наклали санкції у зв’язку з війною в Україні та кому слід приготуватися.",
};

export default ua;
